import * as React from 'react';
import { FC, ReactNode, useState } from 'react';

import {
  AdministratorApi,
  DataProvider,
  DomainData,
  CoreDataApi,
  RegionType,
} from '@reloaded/integration';

//TODO move where?
const useAdministratorPictureAndTeams = () => {
  return AdministratorApi.retrieveAllAdministrators('profilePictures', 'teams');
};
const useCompetences = () => {
  return CoreDataApi.getCompetences();
};
const useDepartments = () => {
  return CoreDataApi.getDepartments();
};
const useRegions = () => {
  return CoreDataApi.searchRegions({
    regionType: [RegionType.REGIONAL_COUNCIL, RegionType.MUNICIPALITY],
  });
};
//TODO remove ?
export const DomainDataProvider: FC<{ children: ReactNode }> = (props) => {
  let [domainData, setDomainData] = useState<DomainData>(() => ({
    getAdministrators: () => useAdministratorPictureAndTeams(),
    getCompetences: () => useCompetences(),
    getDepartments: () => useDepartments(),
    getRegionalCouncils: () => useRegions(),
  }));

  return <DataProvider domainData={domainData}>{props.children}</DataProvider>;
};
