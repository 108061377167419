import * as React from 'react';
import {List, Popover, PopupContainer, Text} from "@reloaded/core-components";
import {Watch, WatchApi} from "@reloaded/integration";
import {ResourceLink} from "@reloaded/reloaded-ui";

export interface WatchMenuProps {
    trigger: React.ReactNode;
}

export const WatchMenu = ({trigger}: WatchMenuProps) => {
    const [watches, setWatches] = React.useState<Watch[]>([])
    const [error, setError] = React.useState<Error | null>(null)
    const [open, setOpen] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (open) {
            WatchApi.searchWatches({})
                .then((watches) => {
                    setWatches(watches);
                })
                .catch(setError);
        }
    }, [open]);

    if (error) {
        throw error;
    }

    return (
        <Popover onOpenChange={setOpen}>
            <Popover.Trigger>
                {trigger}
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content side="top" align="start" avoidCollisions>
                    <PopupContainer style={{padding: '10px'}}>
                        <List density={List.Density.SPARSE}>
                            {watches.map((watch) => {
                                return (
                                    <List.Item key={watch.id} style={{fontSize: 'var(--r-font-size-medium)'}}>
                                        <ResourceLink resourceName={watch.watchedResourceName}/>
                                    </List.Item>
                                )
                            })}
                        </List>
                    </PopupContainer>
                </Popover.Content>
            </Popover.Portal>
        </Popover>
    );
}