export const useAPICache = (urls: string[]) => {
  urls.forEach((url) => {
    console.log(`Caching url: ${url}`);
    navigator.serviceWorker.controller.postMessage({
      type: 'ADD_TO_CACHE',
      url: url,
    });
  });
};

type registerServiceWorkerProps = {
  cacheUrls?: string[];
};

export const registerServiceWorker = ({
  cacheUrls,
}: registerServiceWorkerProps) => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/sw.js')
      .then((registration) => {
        if (registration.waiting) {
          console.debug('Service worker waiting');
        } else if (registration.installing) {
          console.debug('Service worker installing');
        } else if (registration.active) {
          console.debug('Service worker active');

          if (cacheUrls) {
            useAPICache(cacheUrls);
          }
        }
      })
      .catch((error) =>
        console.error('Service worker registration failed: ', error)
      );
  }
};
