import {
  Actionable,
  Dropdown,
  Flex,
  LoadingIndicator,
  LoadingIndicatorContext,
  QueryHandler,
  Select,
  SelectProps,
  Text
} from '@reloaded/core-components';
import * as React from 'react';
import { DropdownShowcaseTestData, Pet } from './DropdownShowcase.testdata';
import { CoreDataApi, Language, Region, RegionType } from '@reloaded/integration';

const PET_QUERY_HANDLER: QueryHandler<Pet> = ({term, offset, count}) => {
    return new Promise((resolve, reject) => {
        window.setTimeout(() => {
            resolve(
                DropdownShowcaseTestData.filter(
                    ({name}) =>
                        !term || name.toLowerCase().includes(term.toLowerCase()),
                ).slice(offset, offset + count),
            );
        }, 100);
    });
};

const REGION_QUERY_HANDLER: QueryHandler<Region> = ({term, offset, count}) => {
    return CoreDataApi.searchRegions(
        {
            term,
            regionType: [RegionType.COUNTRY],
            offset,
            count,
        },
        'symbols'
    );
}

function ExampleDropdown1({title}: { title?: string }) {
    const [value, setValue] = React.useState<string | null>(null);
    const [expanded, setExpanded] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState<{
        id: number;
        name: string;
    }>();

    return (
        <Dropdown
            value={expanded ? value : selectedValue?.name ?? ''}
            onValueChanged={setValue}
            expanded={expanded}
            onExpandedChanged={setExpanded}
            title={title}
        >
            {DropdownShowcaseTestData.filter(
                ({name}) =>
                    !value || name.toLowerCase().includes(value.toLowerCase()),
            ).map((item) => (
                <Dropdown.Item key={item.id} onClick={() => setSelectedValue(item)}>
                    {item.name}
                </Dropdown.Item>
            ))}
        </Dropdown>
    );
}

function ExampleSelect1({title, ...props}: { title?: string } & Partial<Omit<SelectProps<Pet, number>, 'title' | 'queryItems' | 'value' | 'valueToString'>>) {
    const [value, setValue] = React.useState<Pet | null>(null);

    return (
        <Select<Pet, number>
            {...props}
            value={value}
            onValueChanged={({value}) => setValue(value)}
            idOf={(pet) => pet.id}
            valueToString={(pet) => pet.name}
            title={title}
            loadingIndicator={
                <LoadingIndicator context={LoadingIndicator.Context.DROPDOWN}/>
            }
            queryItems={PET_QUERY_HANDLER}
        >
      {(item) => {
          return <div key={item.id}>{item.name}</div>;
      }}
        </Select>
    );
}

function ExampleSelect2({title, ...props}: { title?: string } & Partial<Omit<SelectProps<Pet, number>, 'title' | 'queryItems' | 'value' | 'valueToString'>>) {
    const [value, setValue] = React.useState<Region | null>(null);
    
    return (
        <Flex flexDirection="column" gap="var(--r-spacing-2)">
            <Select<Region, number>
                //{...props}
                value={value}
                onValueChanged={({value}) => setValue(value)}
                idOf={(region) => region.id}
                valueToString={(region) => region.name.sv}
                title={title}
                loadingIndicator={
                    <LoadingIndicator context={LoadingIndicator.Context.DROPDOWN}/>
                }
                queryItems={REGION_QUERY_HANDLER}
            >
                {(item) => {
                    return <div key={item.id}>{item.name.sv}</div>;
                }}
            </Select>

        </Flex>
    );
}

function ExampleSelect3({title, ...props}: { title?: string } & Partial<Omit<SelectProps<Language, number>, 'title' | 'queryItems' | 'value' | 'valueToString'>>) {
    const [value, setValue] = React.useState<Language | null>(null);

    return (
        <Select<Language, number>
            {...props}
            value={value}
            onValueChanged={({value}) => setValue(value)}
            idOf={(region) => region.id}
            valueToString={(region) => region.name.sv}
            title={title}
            loadingIndicator={
                <LoadingIndicator context={LoadingIndicator.Context.DROPDOWN}/>
            }
            queryItems={({term, offset, count}) => CoreDataApi.searchLanguages(
                {
                    name: term,
                    offset,
                    count,
                }
            )}
        >
            {(item) => {
                return <div key={item.id}>{item.name.sv}</div>;
            }}
        </Select>
    );
}

export function DropdownShowcase() {
    const [selectedCountryId, setSelectedCountryId] = React.useState<number>(1);

    return (
        <Flex
            flexDirection="column"
            style={{margin: 'var(--r-spacing-2)', maxWidth: '300px'}}
            gap="var(--r-spacing-2)"
        >
            <Text fontSize={Text.FontSize.XLARGE} fontWeight={Text.FontWeight.BOLD}>
                Beskrivning
            </Text>

            <Text
                fontSize={Text.FontSize.MEDIUM}
                fontWeight={Text.FontWeight.REGULAR}
            >
                En <i>dropdown</i> används för att visa ett sökfilter-värde /
                placeholder som möjliggör sök i dess innehåll. I grund och botten
                används inte filtret utan det är upp till sammanhanget att använda det.
            </Text>

            <Actionable>{(actioning) => (
                <ExampleSelect2
                    title="Välj land"
                    naked={!actioning}
                />
            )}</Actionable>

            <ExampleSelect2
                title="Välj land"
            />

          <Text fontSize={Text.FontSize.XLARGE} fontWeight={Text.FontWeight.BOLD}>
            Dropdown med laddningsindikator

            <Select<{ id: number }, number>
              expanded
              queryItems={() => new Promise<{id: number}[]>(() => {})}
              idOf={x => x.id}
              loadingIndicator={<LoadingIndicator context={LoadingIndicatorContext.DROPDOWN} />}
              valueToString={x => ""}>
              {() => <div />}
            </Select>
          </Text>
        </Flex>
    );
}
