import * as React from 'react';
import {
  Actionable,
  Flex,
  Text,
  Input,
  IconOverlay,
  Icon,
  IconButton,
  QueryHandler,
  ManagedChipInput,
} from '@reloaded/core-components';
import { func } from 'prop-types';

const ActionableInput = () => {
  const [value, setValue] = React.useState('Hello, world!');
  const [editing, setEditing] = React.useState(false);

  return (
    <Actionable>
      {(actioning) => {
        return (
          <IconOverlay
            icon={
              <IconButton onClick={() => {}}>
                <Icon name="trashcan" />
              </IconButton>
            }
          >
            <Input
              naked={!actioning}
              value={value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue(e.target.value)
              }
            />
          </IconOverlay>
        );
      }}
    </Actionable>
  );
};

function ActionableChipInput() {
  interface Superhero {
    id: number;
    name: string;
  }

  const queryHandler = React.useMemo(() => {
    const superheroes: Superhero[] = [
      { id: 1, name: 'Superman' },
      { id: 2, name: 'Batman' },
      { id: 3, name: 'Spiderman' },
      { id: 4, name: 'Ironman' },
      { id: 5, name: 'Captain America' },
      { id: 6, name: 'Hulk' },
      { id: 7, name: 'Thor' },
    ];

    return (({ term, offset, count }) =>
      Promise.resolve(
        superheroes
          .filter(
            (x) => !term || x.name.toLowerCase().includes(term.toLowerCase())
          )
          .slice(offset, offset + count)
      )) as QueryHandler<Superhero>;
  }, []);

  const [selectedValues, setSelectedValues] = React.useState<Superhero[]>([]);

  return (
    <Actionable>
      {(actioning) => (
        <Flex flexDirection="row" gap="var(--r-spacing-1)" alignItems="center">
          <ManagedChipInput<Superhero, number>
            queryItems={queryHandler}
            selectedValues={selectedValues}
            onSelectedValuesChanged={({values}) => setSelectedValues(values)}
            idOf={(item) => item.id}
            renderSuggestion={(item) => <div>{item.name}</div>}
            valueToString={(item) => item.name}
            placeholder="Type to search..."
            loadingIndicator={<div>Loading...</div>}
            naked={!actioning}
          />

          <Icon name="pen" size={Icon.Size.XSMALL} />
        </Flex>
      )}
    </Actionable>
  );
}

export function ActionableShowcase() {
  return (
    <Flex
      flexDirection="column"
      style={{ margin: 'var(--r-spacing-2)', maxWidth: '300px' }}
      gap="var(--r-spacing-2)"
    >
      <Text fontSize={Text.FontSize.XLARGE} fontWeight={Text.FontWeight.BOLD}>
        Beskrivning
      </Text>

      <Text
        fontSize={Text.FontSize.MEDIUM}
        fontWeight={Text.FontWeight.REGULAR}
      >
        En <i>Actionable</i> för att visa ett värde men göra det redigeringsbart
        när användaren t.ex. klickar på det. Det ska också gå att t.ex. genom
        tab aktivera redigeringsläget.
      </Text>

      <Text fontSize={Text.FontSize.XLARGE} fontWeight={Text.FontWeight.BOLD}>
        Exempel 1
      </Text>

      <ActionableInput />

      <Text fontSize={Text.FontSize.XLARGE} fontWeight={Text.FontWeight.BOLD}>
        Exempel 2 (Chip Input)
      </Text>

      <ActionableChipInput />

      <Text fontSize={Text.FontSize.XLARGE} fontWeight={Text.FontWeight.BOLD}>
        Exempel 3
      </Text>

      <Actionable>
        {(actioning) => (
          <Flex flexDirection="column" gap="var(--r-spacing-2)">
            <Input naked={!actioning} placeholder="Type to search..." />

            <div>
              The actionable state can be activated by clicking here too
            </div>
          </Flex>
        )}
      </Actionable>
    </Flex>
  );
}
