import { ExpanderSection, Text } from '@reloaded/core-components';
import React from 'react';

function ExpanderSectionShowcase() {
  return (
    <ExpanderSection>
      <ExpanderSection.Item
        value="item-1"
        title={
          <Text
            fontSize={Text.FontSize.LARGE}
            fontWeight={Text.FontWeight.BOLD}
          >
            Steg 1
          </Text>
        }
        subtitle={
          <Text fontSize={Text.FontSize.SMALL}>En sektion för steg 1</Text>
        }
      >
        <div>Hejsan</div>
      </ExpanderSection.Item>
      <ExpanderSection.Item
        value="item-2"
        title={
          <Text
            fontSize={Text.FontSize.LARGE}
            fontWeight={Text.FontWeight.BOLD}
          >
            Steg 2
          </Text>
        }
        subtitle={
          <Text fontSize={Text.FontSize.SMALL}>
            En sektion för steg 2 som är låst
          </Text>
        }
        disabled
      >
        <div>Ajabaja</div>
      </ExpanderSection.Item>
    </ExpanderSection>
  );
}

export default ExpanderSectionShowcase;
