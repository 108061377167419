import {
  ContentEditorToggle,
  useContentBundle,
} from '@reloaded/content-management';
import {
  Badge,
  CalendarButton,
  Container,
  DropdownMenu,
  DynamicToolbar,
  DynamicToolbarManager,
  DynamicToolbarManagerContext,
  Grid,
  LoadingIndicator,
  LoadingIndicatorContext,
  SettingsButton,
  Sidebar,
  SnackbarArea,
  Text,
  TopMenu,
  useLogger,
} from '@reloaded/core-components';
import { GlobalSmartSearch, useMyProfessions } from '@reloaded/reloaded-ui';
import * as React from 'react';
import { useMemo } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../apps';
import DashboardPage from '../dashboard/DashboardPage';
import { ChatbotIcon } from '../experiments/chatbot/ChatbotIcon';
import { ApplicationSidebar } from './ApplicationSidebar';
import { MyProfileButton } from './MyProfileButton';
import { SearchResultHit } from '@reloaded/integration';
import { WatchMenu } from './WatchMenu';
import PersonalizationDialog from './PersonalizationDialog';
import { NotificationsMenuButton } from './NotificationsMenuButton';
import ErrorSnackbarNotification from '../events/ErrorSnackbarNotification';
import { useDevUser } from '../hooks/useDevUser';
import { LoadProjectOpeningApp } from '../apps/ProjectOpeningRoutes';
import ProjectOpeningMenu from './ProjectOpeningMenu';
import ApplicationRoute from './ApplicationRoute';

const NAVIGATION_CONTENT_PATH = 'frontend.navigation';

function ApplicationLayoutFull({ providers }: { providers?: React.ReactNode }) {
  const logger = useLogger('ApplicationLayout');
  const dynamicToolbarManager = useMemo(() => new DynamicToolbarManager(), []);
  const { professionIds, loading, error } = useMyProfessions();
  const navigate = useNavigate();
  const content = useContentBundle(NAVIGATION_CONTENT_PATH);
  const [personalizationDialogOpen, setPersonalizationDialogOpen] =
    React.useState(false);
  return (
    <>
      {personalizationDialogOpen && (
        <PersonalizationDialog
          requestClose={() => setPersonalizationDialogOpen(false)}
        />
      )}
      <SnackbarArea>
        <ErrorSnackbarNotification />
        <DynamicToolbarManagerContext.Provider value={dynamicToolbarManager}>
          <Grid
            className="r-application-layout"
            style={{ height: '100vh', overflow: 'hidden', width: '100vw' }}
          >
            <DynamicToolbar
              items={[
                {
                  type: 'tab',
                  label: <WatchMenu trigger={<Text>Bevakningar</Text>} />,
                },
                {
                  type: 'tab',
                  label: 'Processer',
                  icon: <Badge label="1" />,
                },
                { type: 'tab', label: 'Chat' },
                { type: 'tab', label: 'Egna anteckningar' },
                { type: 'tab', label: 'Kalkylator' },
              ]}
            />
            {providers}
            <Container className="r-application-content">
              <Routes>
                <Route path="/" element={<DashboardPage />} />
                <Route path="/dashboard/*" element={<DashboardPage />} />
                <Route path="*" element={<AppRoutes />} />
              </Routes>
            </Container>

            <TopMenu>
              <TopMenu.LeftSection>
                <GlobalSmartSearch
                  professionIds={professionIds}
                  onItemSelected={(item: SearchResultHit) => {
                    const match =
                      /r:(?<resourceType>[^:]+):(?<resourceId>\d+)/.exec(
                        item._id,
                      );

                    if (!match) {
                      return;
                    }

                    const { resourceType, resourceId } = match.groups!;

                    if (resourceType === 'consultant') {
                      navigate(`/consultants/${resourceId}`);
                    }
                    if (resourceType === 'client') {
                      navigate(`/client-profiles/${resourceId}`);
                    }
                  }}
                />
              </TopMenu.LeftSection>
              <TopMenu.RightSection>
                <ContentEditorToggle />
                <ChatbotIcon />
                <NotificationsMenuButton />
                <SettingsButton>
                  <DropdownMenu.Item
                    onSelect={() => setPersonalizationDialogOpen(true)}
                  >
                    Personifiering
                  </DropdownMenu.Item>
                </SettingsButton>
                <CalendarButton />

                <span className="r-top-menu-last">
                  <MyProfileButton />
                </span>
              </TopMenu.RightSection>
            </TopMenu>

            <ApplicationSidebar
              content={content}
              contentPath={NAVIGATION_CONTENT_PATH}
            />
          </Grid>
        </DynamicToolbarManagerContext.Provider>
      </SnackbarArea>
    </>
  );
}

function RestrictiveApplicationLayout({
  providers,
}: {
  providers?: React.ReactNode;
}) {
  const dynamicToolbarManager = useMemo(() => new DynamicToolbarManager(), []);
  const [personalizationDialogOpen, setPersonalizationDialogOpen] =
    React.useState(false);
  const [selectedRoute, setSelectedRoute] = React.useState(
    ApplicationRoute.NEED,
  );
  const navigate = useNavigate();

  const handleRouteChange = (
    route: string,
    item: (typeof ApplicationRoute)[keyof typeof ApplicationRoute],
  ) => {
    setSelectedRoute(item);
    navigate(route);
  };
  return (
    <>
      {personalizationDialogOpen && (
        <PersonalizationDialog
          requestClose={() => setPersonalizationDialogOpen(false)}
        />
      )}
      <SnackbarArea>
        <ErrorSnackbarNotification />
        <DynamicToolbarManagerContext.Provider value={dynamicToolbarManager}>
          <Grid
            className="r-application-layout"
            style={{ height: '100vh', overflow: 'hidden', width: '100vw' }}
          >
            <DynamicToolbar items={[]} />
            {providers}
            <Container className="r-application-content">
              <Routes>
                <Route
                  path="/project-openings/*"
                  element={LoadProjectOpeningApp()}
                />
                <Route
                  path="*"
                  element={<Navigate to="/project-openings/" replace />}
                />
              </Routes>
            </Container>

            <TopMenu>
              <TopMenu.LeftSection>
                <div></div>
              </TopMenu.LeftSection>
              <TopMenu.RightSection>
                <span className="r-top-menu-last">
                  <MyProfileButton />
                </span>
              </TopMenu.RightSection>
            </TopMenu>

            <Sidebar>
              <ProjectOpeningMenu
                contentPath={NAVIGATION_CONTENT_PATH}
                selectedRoute={selectedRoute}
                onRouteChanged={({ path, route }) =>
                  handleRouteChange(path, route)
                }
              />
            </Sidebar>
          </Grid>
        </DynamicToolbarManagerContext.Provider>
      </SnackbarArea>
    </>
  );
}

export function ApplicationLayout({
  providers,
}: {
  providers?: React.ReactNode;
}) {
  const { isDevUser, loading, error } = useDevUser();
  if (error) {
    throw error;
  }
  if (loading) {
    return <LoadingIndicator context={LoadingIndicatorContext.PAGE} />;
  }
  if (isDevUser) {
    return <ApplicationLayoutFull providers={providers} />;
  }
  return <RestrictiveApplicationLayout providers={providers} />;
}
