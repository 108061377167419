import * as React from 'react';
import {Connection, ConnectionContext} from '@reloaded/websocket';

function createConnection() {
    const {token} = JSON.parse(sessionStorage.getItem('reloaded.auth.key'));
    const {protocol: browserProtocol, host: browserHost} = global.location;

    const overrideWebSocketUri = localStorage.getItem('reloaded.websocket.uri');

    const effectiveWebSocketUri = `${
        overrideWebSocketUri ||
        `${browserProtocol === 'https:' ? 'wss' : 'ws'}://${browserHost}`
    }/websocket-gateway-api/v1/websocket`;

    return new Connection(effectiveWebSocketUri, {
        authorizationSupplier: () => token,
        authorizationParameterName: 'token',
    });
}

export default function WebSocketConnection({
                                                children,
                                            }: {
    children: React.ReactNode;
}) {
    const connection = React.useMemo(() => createConnection(), []);

    React.useEffect(() => {
        connection.connect();
        return () => connection.disconnect();
    }, [connection]);

    return (
        <ConnectionContext.Provider value={{connection}}>
            <>{children}</>
        </ConnectionContext.Provider>
    );
}
