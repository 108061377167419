import * as React from 'react';
import { Message } from '@reloaded/messaging';

export interface NotificationHandler {
  renderNotification(message: Message): React.ReactNode;
}

export type NotificationRoute = {
  accept: (message: Message) => boolean;
  handler: NotificationHandler;
};

class NotificationManager {
  private _handlers: NotificationRoute[] = [];

  getNotificationHandler(message: Message): NotificationHandler | null {
    const handler = this._handlers.find((handler) => handler.accept(message));

    return handler ? handler.handler : null;
  }

  route(route: NotificationRoute) {
    const copy = { ...route };

    this._handlers.push(copy);

    return () => {
      this._handlers = this._handlers.filter((h) => h !== copy);
    };
  }
}

const NotificationManagerContext =
  React.createContext<NotificationManager | null>(new NotificationManager());

export function useNotificationManager() {
  return React.useContext(NotificationManagerContext);
}
