import { App, AppEntry } from '@reloaded/core-ui';
import { importRemote } from 'module-federation-import-remote';
import { Route } from 'react-router-dom';

import * as React from 'react';
import { getAppUri } from '../app/getAppUri';

export function loadBookingApp() {
  return importRemote({
    url: getAppUri('bookingApp', '/apps/booking-app'),
    scope: 'bookingApp',
    module: 'bookingApp',
    remoteEntryFileName: 'remoteEntry.js',
  });
}

export const routes = () => (
  <Route
    path="/bookings/*"
    element={
      <AppEntry
        path="/bookings"
        loadingFallback={<div>Loading...</div>}
        load={() =>
          loadBookingApp()
            .then((module: any) => {
              console.log('Completed loading booking app');
              return module.default as App;
            })
            .catch((e) => {
              console.error('Got an error when loading booking app: ' + e);

              return Promise.reject(e);
            })
        }
      />
    }
  />
);
