enum ApplicationRoute {
  HOME,
  CLIENT_PROFILES,
  CONSULTANT_PROFILES,
  BOOKINGS,
  AVAILABILITY,
  AGREEMENT,
  STATUS,
  PRESENTATIONS,
  NEED,
  NEED_MAPPINGS,
  ORDERS,
  ADMIN,
  SETTINGS_COMPETENCES,
  SETTINGS_CHECKS,
  SETTINGS_PREFERENCES,
  SETTINGS_CALENDAR,
  SETTINGS_REVIEWS,
  SETTINGS_COMMUNICATION,
  SETTINGS_PERMISSION,
  AI,
}

export default ApplicationRoute;
