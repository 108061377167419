import * as React from 'react';
import { useNotificationManager } from './NotificationManager';
import MessageDomainRouter from './MessageDomainRouter';

export const RegisterNotificationHandlers = () => {
  const notificationManager = useNotificationManager();

  React.useEffect(() => {
    notificationManager.route(MessageDomainRouter);
  }, []);

  return <></>;
};
