import * as React from 'react';
import { DateTime } from 'luxon';
import { DatePicker, Flex, Text } from '@reloaded/core-components';

export const DatePickerShowcase = () => {
  const [value, setValue] = React.useState<DateTime[] | undefined>([DateTime.fromISO("2024-12-01")]);
  const [value2, setValue2] = React.useState<DateTime[] | undefined>([
    DateTime.fromISO('2020-03-31T02:00:00.000+02:00'),
  ]);

  return (
    <Flex flexDirection="column" gap="var(--r-spacing-4)">
      <h1>DatePickerShowcase</h1>

      <Flex flexDirection="column" gap="var(--r-spacing-2)">
        <Text fontSize={Text.FontSize.XLARGE}>
          Date picker without controls
        </Text>
        <DatePicker
          style={{ width: '370px' }}
          value={value}
          onChange={({ value }) => setValue(value)}
        />
      </Flex>

      <Flex flexDirection="column" gap="var(--r-spacing-2)">
        <Text fontSize={Text.FontSize.XLARGE}>
          Date picker with header and footer
        </Text>

        <DatePicker
          style={{ width: '370px' }}
          value={value}
          onChange={({ value }) => setValue(value)}
        >
          <DatePicker.Header style={{ background: 'blue' }}>
            This is a header
          </DatePicker.Header>
          <DatePicker.Footer style={{ background: 'red' }}>
            This is a footer
          </DatePicker.Footer>
        </DatePicker>
      </Flex>

      <Flex flexDirection="column" gap="var(--r-spacing-2)">
        <Text fontSize={Text.FontSize.XLARGE}>Date picker with controls</Text>

        <DatePicker
          style={{ width: '370px' }}
          value={value}
          onChange={({ value }) => setValue(value)}
        >
          <DatePicker.Header>
            <DatePicker.Controls />
          </DatePicker.Header>
        </DatePicker>
      </Flex>

      <Flex flexDirection="column" gap="var(--r-spacing-2)">
        <Text fontSize={Text.FontSize.XLARGE}>Date picker button</Text>
        <div
          style={{
            width: '400px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <DatePicker.Button
            value={value}
            onSubmit={(value) => setValue(value)}
          />
        </div>
      </Flex>

      <Flex flexDirection="column" gap="var(--r-spacing-2)">
        <Text fontSize={Text.FontSize.XLARGE}>
          Date picker button with initial selection
        </Text>
        <div
          style={{
            width: '400px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <DatePicker.Button
            value={value2}
            onSubmit={(value) => setValue2(value)}
          />
        </div>
      </Flex>
    </Flex>
  );
};
