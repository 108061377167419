import { AppEntry, App } from '@reloaded/core-ui';
import { Route } from 'react-router-dom';
import * as React from 'react';
import { importRemote } from 'module-federation-import-remote';
import { getAppUri } from '../app/getAppUri';

const COMPONENT_GALLERY_APP_KEY = 'componentGalleryApp';

export default function ComponentGalleryRoutes() {
  return (
    <Route
      path="/component-gallery/*"
      element={(
        <AppEntry<App>
          path="/component-gallery"
          loadingFallback={<div>Loading...</div>}
          load={() => importRemote({
            url: getAppUri(COMPONENT_GALLERY_APP_KEY, '/apps/component-gallery-app'),
            scope: COMPONENT_GALLERY_APP_KEY,
            module: COMPONENT_GALLERY_APP_KEY,
            remoteEntryFileName: 'remoteEntry.js',
          })
            .then((module: unknown) => {
              // eslint-disable-next-line no-console
              console.log(`Completed loading ${COMPONENT_GALLERY_APP_KEY}`);

              const { default: app } = module as { default: App };

              return app;
            })
            .catch((e) => {
              // eslint-disable-next-line no-console
              console.error(`Got an error when loading ${COMPONENT_GALLERY_APP_KEY}`, e);

              return Promise.reject(e);
            })}
        />
      )}
    />
  );
}
