import * as React from 'react';
import {ReactNode, useEffect} from "react";
import {useRerouting, LocalStorageRerouteRules} from "@reloaded/integration";

export const IntegrationConfig = ({enableRerouting, children}: {enableRerouting:boolean, children: ReactNode}) => {
    useEffect(() => {
        if (enableRerouting) {
            let disposeable = useRerouting(new LocalStorageRerouteRules("r.rerouting"));

            return () => disposeable.close();
        }
    }, [useRerouting]);

    return <React.Fragment>{children}</React.Fragment>
}