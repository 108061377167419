import { Button, Flex, Text, Toggle, ButtonAlternatives } from '@reloaded/core-components';
import * as React from 'react';

export function ButtonShowcase() {
  const [toggle1Checked, setToggle1Checked] = React.useState(false);
  const [toggle2Checked, setToggle2Checked] = React.useState(false);
  const [alternativeButtonMessage, setAlternativeButtonMessage] = React.useState<string>();

  return (
    <Flex
      flexDirection="column"
      style={{ padding: 'var(--r-padding-5)', gap: 'var(--r-spacing-3)' }}
    >
      <Text fontSize={Text.FontSize.XLARGE} fontWeight={Text.FontWeight.BOLD}>
        Regular buttons
      </Text>
      <Flex flexDirection="row" style={{ gap: 'var(--r-spacing-1)' }}>
        <Button>Default</Button>
        <Button variant={Button.Variant.SECONDARY}>Default</Button>
        <Button variant={Button.Variant.DANGER}>Default</Button>
        <Button variant={Button.Variant.HOLLOW}>Default</Button>
        <Button variant={Button.Variant.GHOST}>Default</Button>
        <Button variant={Button.Variant.DANGER_GHOST}>Default</Button>
      </Flex>

      <Text fontSize={Text.FontSize.XLARGE} fontWeight={Text.FontWeight.BOLD}>
        Toggles
      </Text>
      <Flex flexDirection="row" style={{ gap: 'var(--r-spacing-1)' }}>
        <Toggle
          value={toggle1Checked}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setToggle1Checked(e.target.checked)
          }
        />

        <Toggle
          value={toggle2Checked}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setToggle2Checked(e.target.checked)
          }
        />
      </Flex>

      <Text fontSize={Text.FontSize.XLARGE} fontWeight={Text.FontWeight.BOLD}>
        Buttons with alternatives
      </Text>
      <Flex flexDirection="column" style={{ gap: 'var(--r-spacing-1)' }}>
        <ButtonAlternatives buttons={[
          <Button onClick={() => setAlternativeButtonMessage('Button 1 clicked')}>Button 1</Button>,
          <Button onClick={() => setAlternativeButtonMessage('Button 2 clicked')}>Button 2</Button>,
          <Button onClick={() => setAlternativeButtonMessage('Button 3 clicked')}>Button 3</Button>,
        ]} />

        {alternativeButtonMessage && (
          <Text>{alternativeButtonMessage}</Text>
        )}
      </Flex>
    </Flex>
  );
}
