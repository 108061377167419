import * as React from 'react';
import { useEventManager } from '@reloaded/core-components';
import { loadApp } from '../apps/loadApp';
import { OutletType, useOutletManager } from '@reloaded/reloaded-ui';

const EVENT_PATTERN = /^r:[a-z-]+:.+$/;

export const EventHandlers = () => {
  const eventManager = useEventManager();
  const outletManager = useOutletManager();
  const context = React.useMemo(() => {
    return {
      renderOutlet: ({
        type,
        render,
      }: {
        type: OutletType;
        render: (opts: { dispose: () => void }) => React.ReactNode;
      }) => {
        const { dispose } = outletManager.addOutlet({
          type,
          render: () => {
            return render({ dispose });
          },
        });
      },
    };
  }, []);

  React.useEffect(() => {
    const dispose = eventManager.subscribe(EVENT_PATTERN, (event) => {
      const [, app] = event.type.split(':');
      loadApp(app).then((module: any) => {
        if (
          'onEvent' in module.default &&
          typeof module.default.onEvent === 'function'
        ) {
          module.default.onEvent(event, context);
        } else {
          console.error(`${app} app does not have an onEvent function`);
        }
      });
    });

    return () => {
      dispose.unsubscribe();
    };
  }, []);

  return <></>;
};
