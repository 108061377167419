import { App, AppEntry } from '@reloaded/core-ui';
import { Route } from 'react-router-dom';
import * as React from 'react';
import { importRemote } from 'module-federation-import-remote';
import { getAppUri } from '../app/getAppUri';

const CLIENT_PROFILE_APP_KEY = 'clientProfileApp';

export default function ClientProfileRoutes() {
  return (
    <Route
      path="/client-profiles/*"
      element={(
        <AppEntry<App>
          path="/client-profiles"
          loadingFallback={<div>Loading...</div>}
          load={() => importRemote({
            url: getAppUri(CLIENT_PROFILE_APP_KEY, '/apps/client-profile-app'),
            scope: CLIENT_PROFILE_APP_KEY,
            module: CLIENT_PROFILE_APP_KEY,
            remoteEntryFileName: 'remoteEntry.js',
          })
            .then((module: unknown) => {
              // eslint-disable-next-line no-console
              console.log(`Completed loading ${CLIENT_PROFILE_APP_KEY}`);

              const { default: app } = module as { default: App };

              return app;
            })
            .catch((e) => {
              // eslint-disable-next-line no-console
              console.error(`Got an error when loading ${CLIENT_PROFILE_APP_KEY}: ${e}`);

              return Promise.reject(e);
            })}
        />
      )}
    />
  );
}
