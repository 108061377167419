import { ChangeEvent, useState } from 'react';
import * as React from 'react';
import { Input } from '@reloaded/core-components';

export interface ChatInputProps {
  onChatMessage: (message: string) => void;
}

export function ChatInput({ onChatMessage }: ChatInputProps) {
  const [value, setValue] = useState('');

  return (
    <Input
      placeholder="Skriv meddelande"
      value={value}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onChatMessage(value);
          setValue('');
        }
      }}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
    />
  );
}
