import * as React from 'react';
import {
    Bindings,
    Skin,
    Theme,
    ThemeRoot,
} from '@reloaded/core-components';
import { PropsWithChildren } from 'react';

export type DefaultThemeProps = PropsWithChildren<{
    bindings: Bindings;
    skin: Skin;
}>;

export default class DefaultTheme
    extends React.Component<DefaultThemeProps, {}>
    implements Theme {
    readonly name: string = 'default';

    constructor(props: DefaultThemeProps) {
        super(props);
    }

    componentDidUpdate(prevProps: Readonly<DefaultThemeProps>) {
        if (prevProps.skin != this.props.skin) {
            prevProps.skin.uninstall();
            this.props.skin.install();
        }

        if (prevProps.bindings != this.props.bindings) {
            prevProps.bindings.uninstall();
            this.props.bindings.install();
        }
    }

    componentDidMount() {
        this.install();
    }
    
    install(): void {
        this.props.bindings.install();
        this.props.skin.install();
    }

    uninstall(): void {
        this.props.bindings.uninstall();
        this.props.skin.uninstall();
    }

    get bindings(): Bindings {
        return this.props.bindings;
    }

    get skin(): Skin {
        return this.props.skin;
    }

    render() {
        return <ThemeRoot theme={this}>{this.props.children}</ThemeRoot>;
    }
}
