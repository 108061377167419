import * as React from 'react';
import { TextBox } from '@reloaded/core-components';
import { TextSuggestions } from '@reloaded/reloaded-ui';

type SuggestionTextBoxProps = React.ComponentProps<typeof TextBox> & {
  renderTarget: (props: React.ComponentProps<typeof TextBox>) => React.ReactNode;
}

export function SuggestionTextBox({renderTarget, ...props}: SuggestionTextBoxProps) {
  return (
    <TextSuggestions onSuggestion={(suggestion) => {
      props.onChange?.({target: {value: suggestion}} as any);
    }}>
      {renderTarget(props)}
    </TextSuggestions>
  );
}