import * as React from 'react';
import {
  WithCoreComponentDecorators,
  CoreComponentDecorator,
  TextBox,
} from '@reloaded/core-components';
import { TextSuggestions } from '@reloaded/reloaded-ui';
import { SuggestionTextBox } from './SuggestionTextBox';

const DECORATORS: { [componentName: string]: CoreComponentDecorator } = {
  TextBox: ({ render, props }) => {
    if ('suffix' in props) {
      return render(props);
    }

    return (
      <SuggestionTextBox
        {...(props as React.ComponentProps<typeof TextBox>)}
        renderTarget={render}
      />
    );
  },
};

export default function ComponentDecorators({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <WithCoreComponentDecorators decorators={DECORATORS}>
      {children}
    </WithCoreComponentDecorators>
  );
}
