import * as React from 'react';
import {
  Flex,
  Icon,
  Label,
  Notification,
  NotificationsButton,
} from '@reloaded/core-components';
import { Message, MessageFilter, useMessages } from '@reloaded/messaging';
import { DateTime } from 'luxon';
import {
  Analysis,
  CalendarApi,
  CalendarEvent,
  Note,
  NotesApi,
} from '@reloaded/integration';
import { ResourceLink } from '@reloaded/reloaded-ui';
import { loadApp } from '../apps/loadApp';
import { useNotificationManager } from '../notifications/NotificationManager';

const filter: MessageFilter = (message) => true;

interface ReminderTriggeredHeader {
  attributes: {
    type: 'REMINDER_TRIGGERED';
  };
}

interface ReminderTriggeredBody {
  type: 'JSON';
  content: {
    subject: string;
    reminderId: number;
    contextResourceNames: string[];
  };
}

function CalendarEvent({ id }: { id: number }) {
  const [{ event, loading, error }, setState] = React.useState<{
    event?: CalendarEvent;
    loading: boolean;
    error?: Error;
  }>({ loading: true });
  const [contentItems, setContentItems] = React.useState<Note[]>();

  React.useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));

    CalendarApi.getEventByEventIdOnly(id, '*')
      .then((event) => {
        setState((prev) => ({ ...prev, event, loading: false }));
      })
      .catch((error) => {
        setState((prev) => ({ ...prev, error, loading: false }));
      });
  }, [id]);

  React.useEffect(() => {
    if (!event) {
      return;
    }

    Promise.all(
      event.content.map((content) => {
        if (content.value.type === 'NOTE') {
          return NotesApi.byNoteId(content.value.noteId);
        } else {
          throw new Error('Unknown content type');
        }
      }),
    )
      .then((notes) => {
        setContentItems(notes);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, [event]);

  if (loading) {
    return <div>Laddar...</div>;
  }

  if (error) {
    return <div>Ett fel uppstod: {error.message}</div>;
  }

  return (
    <>
      <div>
        {//TODO: must be taken from plainText in notes api when implemented!
        contentItems?.map((note) => <div>{note.content.value}</div>)}
      </div>
    </>
  );
}

export function ReminderTriggeredNotification({
  header,
  body,
}: {
  header: ReminderTriggeredHeader;
  body: ReminderTriggeredBody;
}) {
  const [ns, type, id] = body.content.subject.split(':');

  if (ns === 'r') {
    if (type === 'calendar-event') {
      return (
        <NotificationsButton.Item icon={<Icon name="calendar" />}>
          <Flex flexDirection="column" gap="var(--r-spacing-1)">
            {body.content.contextResourceNames.map((resourceName) => (
              <ResourceLink key={resourceName} resourceName={resourceName} />
            ))}
            <CalendarEvent id={parseInt(id)} />
          </Flex>
        </NotificationsButton.Item>
      );
    }
  }

  return null;
}

export function NotificationBody({
  message,
  closeNotifications,
}: {
  message: Message;
  closeNotifications: () => void;
}) {
  const [notificationBody, setNotificationBody] =
    React.useState<React.ReactNode>();
  const notificationManager = useNotificationManager();

  React.useEffect(() => {
    //if (message._embedded.header)

    const handler = notificationManager.getNotificationHandler(message);

    if (handler) {
      setNotificationBody(handler.renderNotification(message));
      return;
    }

    if (message._embedded.header.attributes['type'] === 'REMINDER_TRIGGERED') {
      const body = message._embedded.body as ReminderTriggeredBody;

      setNotificationBody(
        <ReminderTriggeredNotification
          header={message._embedded.header as ReminderTriggeredHeader}
          body={body}
        />,
      );
    }

    if (message._embedded.header.attributes['type'] === 'MORPHEUS_ANALYSES') {
      // TODO: Really want to make this a lot more generic
      const { analyses } = message._embedded.body.content as {
        analyses: {
          analysis: Analysis;
          context?: string;
        }[];
      };

      const analysesByContextType = analyses.reduce(
        (acc, { analysis, context }) => {
          const [ns, type, id] = context.split(':');
          const contextType = ns === 'r' ? type : null;

          return {
            ...acc,
            [contextType || '']: [...(acc[contextType || ''] || []), analysis],
          };
        },
        {} as { [contextType: string]: Analysis[] },
      );

      Object.entries(analysesByContextType).forEach(
        ([contextType, analyses]) => {
          loadApp(contextType).then((app) => {
            const Component = (app as any).default['renderNotification'];

            setNotificationBody((prev) => (
              <>
                {prev}
                {/* TODO: Add renderNotification to App interface */}
                <Component
                  message={message}
                  closeNotifications={closeNotifications}
                />
              </>
            ));
          });
        },
      );
    }
  }, [message.id]);

  return (
    <>
      {notificationBody ?? (
        <Label prefix={<Icon name={'warning'} />}>Okänt meddelande</Label>
      )}
    </>
  );
}

export function NotificationsMenuButton() {
  const { messages, read } = useMessages({ offset: 0, count: 10 }); // This retrieves all messages... not ideal, do a count first and receive messages when the menu is opened
  const notifications = React.useMemo(() => {
    return messages?.map(
      (message) =>
        ({
          id: message.id,
          state: message._embedded.messageState.readDateTime
            ? 'READ'
            : undefined,
          receivedDateTime: DateTime.fromISO(
            message['_embedded'].messageState.receivedDateTime,
          ),
          component: (
            <NotificationBody
              key={`${message.id}`}
              message={message}
              closeNotifications={() => {
                // TODO: Implement
              }}
            />
          ),
        }) as Notification,
    );
  }, [messages]);

  const timerRef = React.useRef<number>();

  function cancelTimeout() {
    if (timerRef.current) {
      window.clearTimeout(timerRef.current);
    }

    timerRef.current = undefined;
  }

  return (
    <NotificationsButton
      notifications={notifications}
      onExpandedChanged={(expanded) => {
        if (timerRef.current) {
          cancelTimeout();
        }

        if (expanded) {
          timerRef.current = window.setTimeout(() => {
            const unreadMessages = messages
              .filter((message) => !message._embedded.messageState.readDateTime)
              .map((message) => message.id);

            if (unreadMessages.length > 0) {
              Promise.all(unreadMessages.map((messageId) => read(messageId)));
            }

            cancelTimeout();
          }, 1000);
        }
      }}
    />
  );
}
