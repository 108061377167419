import React, { ReactNode, useState } from 'react';
import { useEvent } from '@reloaded/core-components';
import { useEntitlements } from '@reloaded/integration';
import { LoginSuccessEvent } from '@reloaded/login-app';

export type LoadEntitlementsOnLoginProps = {
  children: ReactNode;
};

export function LoadEntitlementsOnLogin({
  children,
}: LoadEntitlementsOnLoginProps) {
  const [userIdToLoad, setUserIdToLoad] = useState<number | null>(null);
  useEvent('LoginSuccess', (e: LoginSuccessEvent) => {
    setUserIdToLoad(e.userId);
  });
  //useEntitlements(userIdToLoad !== null ? [userIdToLoad] : []);
  return <>{children}</>;
}
