import * as React from 'react';
import { Outlet, OutletType, useOutletManager } from '@reloaded/reloaded-ui';

export const PortalOutlets = () => {
  const outletManager = useOutletManager();
  const [outlets, setOutlets] = React.useState<Outlet[]>(() =>
    outletManager.getOutlets(OutletType.PORTAL),
  );

  React.useEffect(() => {
    const { dispose } = outletManager.onOutletChange(() => {
      setOutlets(outletManager.getOutlets(OutletType.PORTAL));
    });

    return dispose;
  }, []);

  console.log('PortalOutlets::render', outlets);

  return (
    <>
      {outlets.map((outlet) => (
        <React.Fragment key={outlet.id}>{outlet.render()}</React.Fragment>
      ))}
    </>
  );
};
