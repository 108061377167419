import { Message } from '@reloaded/messaging';
import * as React from 'react';
import { NotificationRoute } from './NotificationManager';
import { loadApp } from '../apps/loadApp';

interface DomainMessage extends Message {
  _embedded: {
    header: {
      attributes: {
        context: {
          domain: string;
        };
      };
    };
  };
}

function isDomainMessage(message: Message): message is DomainMessage {
  if (!('context' in message._embedded.header.attributes)) {
    return false;
  }

  if (!(typeof message._embedded.header.attributes['context'] === 'object')) {
    return false;
  }

  return (
    'domain' in message._embedded.header.attributes['context'] &&
    typeof message._embedded.header.attributes['context']['domain'] === 'string'
  );
}

function AsyncMessageRoute({
  domain,
  message,
}: {
  domain: string;
  message: Message;
}) {
  const [component, setComponent] = React.useState<React.ReactNode | null>(
    null,
  );

  React.useEffect(() => {
    loadApp(domain).then((app) => {
      const Component = (app as any).default['renderNotification'];

      if (!Component) {
        setComponent(<div>No notification handler</div>);
      } else {
        setComponent(<Component message={message} />);
      }
    });
  }, [domain, message]);

  return component;
}

export default {
  accept: (message) => isDomainMessage(message),
  handler: {
    renderNotification(message: Message): React.ReactNode {
      if (!isDomainMessage(message)) {
        // Should not happen due to the predicate
        return null;
      }

      return (
        <AsyncMessageRoute
          domain={message._embedded.header.attributes.context.domain}
          message={message}
        />
      );
    },
  },
} as NotificationRoute;
