import { App, AppEntry } from '@reloaded/core-ui';
import { importRemote } from 'module-federation-import-remote';
import { Route } from 'react-router-dom';

import * as React from 'react';
import { getAppUri } from '../app/getAppUri';

export const routes = () => (
  <Route
    path="/agreements/*"
    element={
      <AppEntry
        path="/agreements"
        loadingFallback={<div>Loading...</div>}
        load={() =>
          importRemote({
            url: getAppUri('agreementApp', '/apps/agreement-app'),
            scope: 'agreementApp',
            module: 'agreementApp',
            remoteEntryFileName: 'remoteEntry.js',
          })
            .then((module: any) => {
              console.log('Completed loading agreement app');
              return module.default as App;
            })
            .catch((e) => {
              console.error('Got an error when loading agreement app: ' + e);

              return Promise.reject(e);
            })
        }
      />
    }
  />
);
