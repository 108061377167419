import * as React from 'react';
import { useRef } from 'react';
import * as pbi from 'powerbi-client';
import { useAuthentication } from '@reloaded/login-app';
import { ErrorPanel } from '@reloaded/reloaded-ui';
import { Dropdown, Flex, Text } from '@reloaded/core-components';

interface EmbedToken {
  id: string;
  token: string;
  expiration: string;
}

interface Report {
  id: string;
  name: string;
  embedUrl: string;
  webUrl: string;
}

const ReportApi = {
  getEmbedToken: async (reportId: string) => {
    const response = await fetch(
      `/report-gateway-api/v1/reports/${reportId}/embed-token`,
    );

    if (response.status !== 200) {
      throw new Error('Failed to fetch embed token');
    }

    const json = await response.json();

    return json as EmbedToken;
  },
  getReports: async () => {
    const response = await fetch('/report-gateway-api/v1/reports');

    if (response.status !== 200) {
      throw new Error('Failed to fetch reports');
    }

    const json = await response.json();

    return json as Report[];
  },
  async getReport(id: string) {
    const response = await fetch(`/report-gateway-api/v1/reports/${id}`);

    if (response.status !== 200) {
      throw new Error('Failed to fetch report');
    }

    const json = await response.json();

    return json as Report;
  },
};

export const ExampleReport = () => {
  const ref = useRef<HTMLDivElement>();
  const { userId } = useAuthentication();
  const [{ token, report, loading, error }, setState] = React.useState<{
    token?: EmbedToken;
    report?: Report;
    loading: boolean;
    error?: Error;
  }>({ loading: true });
  const [reportId, setReportId] = React.useState<string>('19eccbeb-8c4b-42fd-ab88-fc63dfb3938f');
  const [reports, setReports] = React.useState<Report[]>([]);

  React.useEffect(() => {
    ReportApi.getReport(reportId)
      .then((r) => {
        setState((prev) => ({ ...prev, report: r, loading: false }));
        return r;
      })
      .then((r) =>
        ReportApi.getEmbedToken(r.id).then((result) => {
          setState((prev) => ({ ...prev, token: result }));
        }),
      )
      .catch((e) => setState({ error: e, loading: false }));
  }, [reportId]);

  React.useEffect(() => {
    if (!ref.current || !token || !report) {
      return;
    }

    const embedPowerBI = async () => {
      try {
        const embedConfig = {
          pageName: 'ReportSection',
          type: 'report',
          //type: 'visual',
          //visualName: '453ef5f1d4e3c58b8924',
          tokenType: pbi.models.TokenType.Embed,
          accessToken: token.token,
          embedUrl: report.embedUrl,
          id: report.id,
          settings: {
            filterPaneEnabled: true,
            navContentPaneEnabled: true,
          },
        };

        const result = await window.powerbi.embed(ref.current, embedConfig);

        if (result instanceof pbi.Visual) {
          const filter = {
            $schema: 'http://powerbi.com/product/schema#basic',
            filterType: pbi.models.FilterType.Basic,
            target: {
              table: 'sAdministratorChangedEvent',
              column: 'userId',
            },
            operator: 'In',
            values: [`${userId}`],
          };

          result.on(
            'loaded',
            (() => {
              let applied = false;
              return () => {
                if (applied) {
                  return;
                }

                applied = true;

                result
                  .setFilters([filter])
                  .then(() => console.log('Filter applied successfully'))
                  .catch((e) => console.error('Error applying filter', e));
              };
            })(),
          );

          if ('getPages' in result && false) {
            const report = result as pbi.Report;

            report.on('loaded', () => {
              report.getPages().then((pages) => {
                return Promise.all(
                  pages.map((page) => {
                    return page.getVisuals().then((visuals) => {
                      console.log('VISUALS', page, visuals);
                    });
                  }),
                );
              });
            });
          }
        }
      } catch (error) {
        console.error('Error during Power BI embedding or filtering', error);
      }
    };

    embedPowerBI();
  }, [ref.current, token, report, reportId]);

  React.useEffect(() => {
    ReportApi.getReports()
      .then(setReports)
      .catch((e) => console.error('Failed to fetch reports', e));
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Flex flexDirection="column" gap="var(--r-spacing-2)">
      <Text>Alla rapporter</Text>
      <Dropdown value={reportId && reports.find(r => r.id === reportId)?.name}>
        {reports.map((r) => (
          <Dropdown.Item
            key={r.id}
            onClick={() => {
              setReportId(r.id);
            }}
          >
            {r.name}
          </Dropdown.Item>
        ))}
      </Dropdown>

      <Text>Vald rapport</Text>
      <div ref={ref}>
        {error && <ErrorPanel error={error} />}
      </div>
    </Flex>
  );
};
