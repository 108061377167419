import * as React from 'react';
import { ReactNode } from 'react';
import { Routes } from 'react-router-dom';
import { routes as AgreementRoutes } from './agreement';
import { routes as BookingRoutes } from './booking';
import ClientProfileRoutes from './ClientProfileRoutes';
import ComponentGalleryRoutes from './ComponentGalleryRoutes';
import ConsultantRoutes from './ConsultantRoutes';
import PresentationsRoutes from './PresentationsRoutes';
import { ProjectOpeningRoutes } from './ProjectOpeningRoutes';
import SettingsRoutes from './SettingsRoutes';
import { ContextData } from '../experiments/chatbot/context-data';
import { routes as AiRoutes } from './ai';

export function AppRoutes() {
  return (
    <Routes>
      {ClientProfileRoutes()}
      {ComponentGalleryRoutes()}
      {ConsultantRoutes()}
      {ProjectOpeningRoutes()}
      {PresentationsRoutes()}
      {AgreementRoutes()}
      {BookingRoutes()}
      {SettingsRoutes()}
      {AiRoutes()}
    </Routes>
  );
}
export function AppProviders({ children }: { children: ReactNode }) {
  return <ContextData>{children}</ContextData>;
}
