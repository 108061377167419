import {
  Actionable,
  ChipInput,
  Container,
  Flex,
  ManagedChipInput,
  QueryHandler,
  Text,
} from '@reloaded/core-components';
import * as React from 'react';

interface President {
  id: number;
  name: string;
}

const TestData = [
  { id: 1, name: 'Joe Biden' },
  { id: 2, name: 'Kamala Harris' },
  { id: 3, name: 'Donald Trump' },
  { id: 4, name: 'Mike Pence' },
  { id: 5, name: 'Barack Obama' },
  { id: 6, name: 'Michelle Obama' },
  { id: 7, name: 'George W. Bush' },
  { id: 8, name: 'Laura Bush' },
  { id: 9, name: 'Bill Clinton' },
  { id: 10, name: 'Hillary Clinton' },
  { id: 11, name: 'Ronald Reagan' },
  { id: 12, name: 'Nancy Reagan' },
  { id: 13, name: 'Jimmy Carter' },
  { id: 14, name: 'Rosalynn Carter' },
  { id: 15, name: 'Gerald Ford' },
  { id: 16, name: 'Betty Ford' },
  { id: 17, name: 'Richard Nixon' },
  { id: 18, name: 'Pat Nixon' },
  { id: 19, name: 'John F. Kennedy' },
  { id: 20, name: 'Jacqueline Kennedy' },
  { id: 21, name: 'Dwight D. Eisenhower' },
  { id: 22, name: 'Mamie Eisenhower' },
  { id: 23, name: 'Harry S. Truman' },
  { id: 24, name: 'Bess Truman' },
  { id: 25, name: 'Franklin D. Roosevelt' },
];

const loader: QueryHandler<President> = ({ term, offset, count }) =>
  Promise.resolve(
    TestData.filter(
      (x) => !term || x.name.toLowerCase().includes(term.toLowerCase()),
    ).slice(offset, offset + count),
  );

function ExampleActionableChipInput() {
  const [userInteracting, setUserInteracting] = React.useState(false);
  const [selectedValues, setSelectedValues] = React.useState<President[]>([]);

  return (
    <Actionable actioning={userInteracting ? true : undefined}>
      {(actioning) => (
        <ManagedChipInput<President, number>
          selectedValues={selectedValues}
          onSelectedValuesChanged={({ values }) => setSelectedValues(values)}
          placeholder="Type to search..."
          queryItems={loader}
          idOf={(item) => item.id}
          renderSuggestion={(item) => item.name}
          valueToString={(x) => x.name}
          loadingIndicator={<div>Loading...</div>}
          style={{ width: '300px' }}
          naked={!actioning}
          align={actioning ? 'left' : 'right'}
          onUserInteractionChanged={setUserInteracting}
        />
      )}
    </Actionable>
  );
}

export function ChipInputShowcase() {
  const [selectedValues1, setSelectedValues1] = React.useState<President[]>([
    TestData[0],
  ]);

  const [selectedValues2, setSelectedValues2] = React.useState<President[]>([
    TestData[0],
  ]);

  const [selectedValues3, setSelectedValues3] = React.useState<President[]>([
    TestData[0],
  ]);

  const [selectedValues4, setSelectedValues4] = React.useState<President[]>([
    TestData[0],
  ]);

  return (
    <Flex
      flexDirection="column"
      style={{ margin: 'var(--r-spacing-2)' }}
      gap="var(--r-spacing-2)"
    >
      <Text fontSize={Text.FontSize.XLARGE} fontWeight={Text.FontWeight.BOLD}>
        Regular multi-line chip input
      </Text>

      <ManagedChipInput<President, number>
        selectedValues={selectedValues1}
        onSelectedValuesChanged={({ values }) => setSelectedValues1(values)}
        placeholder="Type to search..."
        queryItems={loader}
        idOf={(item) => item.id}
        renderSuggestion={(item) => item.name}
        valueToString={(x) => x.name}
        loadingIndicator={<div>Loading...</div>}
        style={{ width: '300px' }}
      />

      <ManagedChipInput<President, number>
        title="Presidents"
        selectedValues={selectedValues1}
        onSelectedValuesChanged={({ values }) => setSelectedValues1(values)}
        placeholder="Type to search..."
        queryItems={loader}
        idOf={(item) => item.id}
        renderSuggestion={(item) => item.name}
        valueToString={(x) => x.name}
        loadingIndicator={<div>Loading...</div>}
        style={{ width: '300px' }}
      />

      <ManagedChipInput<President, number>
        selectedValues={selectedValues1}
        onSelectedValuesChanged={({ values }) => setSelectedValues1(values)}
        placeholder="Type to search..."
        queryItems={loader}
        idOf={(item) => item.id}
        renderSuggestion={(item) => item.name}
        valueToString={(x) => x.name}
        loadingIndicator={<div>Loading...</div>}
        style={{ width: '500px' }}
      />

      <Text fontSize={Text.FontSize.XLARGE} fontWeight={Text.FontWeight.BOLD}>
        Regular single-line chip input (WiP)
      </Text>

      <ManagedChipInput<President, number>
        selectedValues={selectedValues2}
        onSelectedValuesChanged={({ values }) => setSelectedValues2(values)}
        placeholder="Type to search..."
        queryItems={loader}
        idOf={(item) => item.id}
        renderSuggestion={(item) => item.name}
        valueToString={(x) => x.name}
        loadingIndicator={<div>Loading...</div>}
        style={{ width: '300px' }}
        singleLine
      />

      <ManagedChipInput<President, number>
        selectedValues={selectedValues2}
        onSelectedValuesChanged={({ values }) => setSelectedValues2(values)}
        placeholder="Type to search..."
        queryItems={loader}
        idOf={(item) => item.id}
        renderSuggestion={(item) => item.name}
        valueToString={(x) => x.name}
        loadingIndicator={<div>Loading...</div>}
        style={{ width: '500px' }}
        singleLine
      />

      <Text fontSize={Text.FontSize.XLARGE} fontWeight={Text.FontWeight.BOLD}>
        Naked multi-line chip input
      </Text>

      <ManagedChipInput<President, number>
        selectedValues={selectedValues3}
        onSelectedValuesChanged={({ values }) => setSelectedValues3(values)}
        placeholder="Type to search..."
        queryItems={loader}
        idOf={(item) => item.id}
        renderSuggestion={(item) => item.name}
        valueToString={(x) => x.name}
        loadingIndicator={<div>Loading...</div>}
        style={{ width: '300px' }}
        naked
      />

      <ManagedChipInput<President, number>
        selectedValues={selectedValues3}
        onSelectedValuesChanged={({ values }) => setSelectedValues3(values)}
        placeholder="Type to search..."
        queryItems={loader}
        idOf={(item) => item.id}
        renderSuggestion={(item) => item.name}
        valueToString={(x) => x.name}
        loadingIndicator={<div>Loading...</div>}
        style={{ width: '500px' }}
        naked
      />

      <Text fontSize={Text.FontSize.XLARGE} fontWeight={Text.FontWeight.BOLD}>
        Naked single-line chip input (WiP)
      </Text>

      <ManagedChipInput<President, number>
        selectedValues={selectedValues4}
        onSelectedValuesChanged={({ values }) => setSelectedValues4(values)}
        placeholder="Type to search..."
        queryItems={loader}
        idOf={(item) => item.id}
        renderSuggestion={(item) => item.name}
        valueToString={(x) => x.name}
        loadingIndicator={<div>Loading...</div>}
        style={{ width: '300px' }}
        singleLine
        naked
      />

      <ManagedChipInput<President, number>
        selectedValues={selectedValues4}
        onSelectedValuesChanged={({ values }) => setSelectedValues4(values)}
        placeholder="Type to search..."
        queryItems={loader}
        idOf={(item) => item.id}
        renderSuggestion={(item) => item.name}
        valueToString={(x) => x.name}
        loadingIndicator={<div>Loading...</div>}
        style={{ width: '500px' }}
        singleLine
        naked
      />

      <Text fontSize={Text.FontSize.XLARGE} fontWeight={Text.FontWeight.BOLD}>
        Disabled chip inputs
      </Text>

      <ManagedChipInput<President, number>
        selectedValues={selectedValues1}
        onSelectedValuesChanged={({ values }) => setSelectedValues1(values)}
        placeholder="Type to search..."
        queryItems={loader}
        idOf={(item) => item.id}
        renderSuggestion={(item) => item.name}
        valueToString={(x) => x.name}
        loadingIndicator={<div>Loading...</div>}
        style={{ width: '300px' }}
        disabled
      />

      <ManagedChipInput<President, number>
        selectedValues={selectedValues4}
        onSelectedValuesChanged={({ values }) => setSelectedValues4(values)}
        placeholder="Type to search..."
        queryItems={loader}
        idOf={(item) => item.id}
        renderSuggestion={(item) => item.name}
        valueToString={(x) => x.name}
        loadingIndicator={<div>Loading...</div>}
        style={{ width: '500px' }}
        singleLine
        naked
        disabled
      />
    </Flex>
  );
}
