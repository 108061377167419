import { Icon, Sidebar } from '@reloaded/core-components';
import * as React from 'react';
import { useApplicationContent } from '../hooks/useApplicationContent';
import ApplicationRoute from './ApplicationRoute';

function SettingsMenu({
  selectedRoute,
  onRouteChanged,
}: {
  selectedRoute: ApplicationRoute;
  onRouteChanged: (e: { path: string; route: ApplicationRoute }) => void;
}) {
  const content = useApplicationContent();

  return (
    <Sidebar.Menu icon={<Icon name="settings" />} title="Inställningar">
      <Sidebar.Item
        selected={selectedRoute === ApplicationRoute.SETTINGS_COMPETENCES}
        onClick={() =>
          onRouteChanged({
            path: '/settings/competences',
            route: ApplicationRoute.SETTINGS_COMPETENCES,
          })
        }
      >
        <i className="r-icon-question-mark" />
        <p>
          {content.get(`navigation.settings.competences`, {
            defaultValue: 'Kompetenser',
          })}
        </p>
      </Sidebar.Item>
      <Sidebar.Item
        selected={selectedRoute === ApplicationRoute.SETTINGS_CHECKS}
        onClick={() =>
          onRouteChanged({
            path: '/settings/checks',
            route: ApplicationRoute.SETTINGS_CHECKS,
          })
        }
      >
        <i className="r-icon-question-mark" />
        <p>
          {content.get(`navigation.settings.checks`, {
            defaultValue: 'Kontroller',
          })}
        </p>
      </Sidebar.Item>
      <Sidebar.Item
        selected={selectedRoute === ApplicationRoute.SETTINGS_PREFERENCES}
        onClick={() =>
          onRouteChanged({
            path: '/settings/preferences',
            route: ApplicationRoute.SETTINGS_PREFERENCES,
          })
        }
      >
        <i className="r-icon-question-mark" />
        <p>
          {content.get(`navigation.settings.preferences`, {
            defaultValue: 'Preferences',
          })}
        </p>
      </Sidebar.Item>
      <Sidebar.Item
        selected={selectedRoute === ApplicationRoute.SETTINGS_CALENDAR}
        onClick={() =>
          onRouteChanged({
            path: '/settings/calendar',
            route: ApplicationRoute.SETTINGS_CALENDAR,
          })
        }
      >
        <i className="r-icon-question-mark" />
        <p>
          {content.get(`navigation.settings.calendar`, {
            defaultValue: 'Kalender',
          })}
        </p>
      </Sidebar.Item>
      <Sidebar.Item
        selected={selectedRoute === ApplicationRoute.SETTINGS_REVIEWS}
        onClick={() =>
          onRouteChanged({
            path: '/settings/reviews',
            route: ApplicationRoute.SETTINGS_REVIEWS,
          })
        }
      >
        <i className="r-icon-question-mark" />
        <p>
          {content.get(`navigation.settings.reviews`, {
            defaultValue: 'Uppföljningar',
          })}
        </p>
      </Sidebar.Item>
      <Sidebar.Item
        selected={selectedRoute === ApplicationRoute.SETTINGS_COMMUNICATION}
        onClick={() =>
          onRouteChanged({
            path: '/settings/communication',
            route: ApplicationRoute.SETTINGS_COMMUNICATION,
          })
        }
      >
        <i className="r-icon-question-mark" />
        <p>
          {content.get(`navigation.settings.communication`, {
            defaultValue: 'Kommunikation',
          })}
        </p>
      </Sidebar.Item>
      <Sidebar.Item
        selected={selectedRoute === ApplicationRoute.SETTINGS_PERMISSION}
        onClick={() =>
          onRouteChanged({
            path: '/settings/permission',
            route: ApplicationRoute.SETTINGS_PERMISSION,
          })
        }
      >
        <i className="r-icon-question-mark" />
        <p>
          {content.get(`navigation.settings.permissions`, {
            defaultValue: 'Rättigheter',
          })}
        </p>
      </Sidebar.Item>
    </Sidebar.Menu>
  );
}

export default SettingsMenu;
