import { Flex, Icon } from '@reloaded/core-components';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { DashboardTabs } from './DashboardTabs';
import { ActionableShowcase } from './showcase/ActionableShowcase';
import { ButtonShowcase } from './showcase/ButtonShowcase';
import { ChipInputShowcase } from './showcase/ChipInputShowcase';
import ContactCardShowcase from './showcase/ContactCardShowcase';
import { DropdownShowcase } from './showcase/DropdownShowcase';
import ExpanderSectionShowcase from './showcase/ExpanderSectionShowcase';
import { InputShowcase } from './showcase/InputShowcase';
import { LayoutShowcase } from './showcase/LayoutShowcase';
import { OverflowContainerShowcase } from './showcase/OverflowContainerShowcase';
import TabsShowcase from './showcase/TabsShowcase';
import { DashboardStartPage } from './start/DashboardStartPage';
import { DatePickerShowcase } from './showcase/DatePickerShowcase';
import { StepsShowcase } from './showcase/StepsShowcase';
import { WeekCalendarShowcase } from './showcase/WeekCalendarShowcase';

export default function DashboardPage() {
  return (
    <Flex
      flexDirection="column"
      style={{ padding: 'var(--r-padding-5)', gap: 'var(--r-spacing-1)' }}
    >
      <DashboardTabs />

      <Routes>
        <Route path="" element={<DashboardStartPage />} />
        <Route path="/showcase/chip-input" element={<ChipInputShowcase />} />
        <Route
          path="/showcase/overflow-container"
          element={<OverflowContainerShowcase />}
        />
        <Route path="/showcase/actionable" element={<ActionableShowcase />} />
        <Route path="/showcase/dropdown" element={<DropdownShowcase />} />
        <Route
          path="/showcase/contact-card"
          element={<ContactCardShowcase />}
        />
        <Route
          path="/showcase/expander"
          element={<ExpanderSectionShowcase />}
        />
        <Route path="/showcase/buttons" element={<ButtonShowcase />} />
        <Route path="/showcase/tabs" element={<TabsShowcase />} />
        <Route path="/showcase/layout" element={<LayoutShowcase />} />
        <Route path="/showcase/inputs" element={<InputShowcase />} />
        <Route path="/showcase/date-picker" element={<DatePickerShowcase />} />
        <Route path="/showcase/steps" element={<StepsShowcase />} />
        <Route
          path="/showcase/week-picker"
          element={<WeekCalendarShowcase />}
        />
        <Route
          path="*"
          element={
            <div
              style={{
                height: '80vh',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icon
                name="cheese"
                style={{
                  height: '100%',
                  width: '100%',
                }}
              />
            </div>
          }
        />
      </Routes>
    </Flex>
  );
}
