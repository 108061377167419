import { Icon, Sidebar } from '@reloaded/core-components';
import React from 'react';
import { useApplicationContent } from '../hooks/useApplicationContent';
import ApplicationRoute from './ApplicationRoute';

function SettingsMenu({
  contentPath,
  selectedRoute,
  onRouteChanged,
}: {
  contentPath: string;
  selectedRoute: ApplicationRoute;
  onRouteChanged: (e: { path: string; route: ApplicationRoute }) => void;
}) {
  const content = useApplicationContent();

  return (
    <Sidebar.Item
      selected={selectedRoute === ApplicationRoute.NEED}
      onClick={() =>
        onRouteChanged({
          path: '/project-openings/',
          route: ApplicationRoute.NEED,
        })
      }
    >
      <Icon name="gift" />
      <p>
        {content.get(`${contentPath}.need`, {
          defaultValue: 'Avrop',
        })}
      </p>
    </Sidebar.Item>
  );
}

export default SettingsMenu;
