import { registerServiceWorker } from "./swConfig";

export const configureServiceWorker = () => {
    const cacheUrls = [
        "/administrator-api/v1/administrators?_embed=profilePictures,teams",
        "/core-data-api/v1/organization/departments",
        "/core-data-api/v1/competences",
        "/core-data-api/v1/regions?type=REGIONAL_COUNCIL,MUNICIPALITY",
    ];

    registerServiceWorker({ cacheUrls });
}