import { useEventManager } from '@reloaded/core-components';
import { OutletType, useOutletManager } from '@reloaded/reloaded-ui';
import { useConnection } from '@reloaded/websocket';
import * as React from 'react';
import { loadApp } from '../apps/loadApp';

type RoutableMessage = {
  header: {
    attributes: {
      context: {
        domain: string;
      };
    };
  };
};

function isRoutableMessage(message: any): message is RoutableMessage {
  return (
    message.header &&
    message.header.attributes &&
    message.header.attributes.context &&
    message.header.attributes.context.domain
  );
}

export function PushMessageRouter() {
  const webSocketConnection = useConnection();
  const eventManager = useEventManager();
  const outletManager = useOutletManager();
  const context = React.useMemo(() => {
    return {
      renderOutlet: ({
        type,
        render,
      }: {
        type: OutletType;
        render: (opts: { dispose: () => void }) => React.ReactNode;
      }) => {
        const { dispose } = outletManager.addOutlet({
          type,
          render: () => {
            return render({ dispose });
          },
        });
      },
    };
  }, []);

  React.useEffect(() => {
    const dispose = webSocketConnection.onMessage((envelope) => {
      if ('data' in envelope && typeof envelope.data === 'string') {
        const messages = (JSON.parse(envelope.data) as any[])
          .flatMap((m) => {
            if (isRoutableMessage(m)) {
              return [m];
            } else {
              return [];
            }
          })
          .reduce(
            (acc, m) => ({
              ...acc,
              [m.header.attributes.context.domain]: [
                ...(acc[m.header.attributes.context.domain] || []),
                m,
              ],
            }),
            {} as { [domain: string]: RoutableMessage[] },
          );

        Object.entries(messages).map(([domain, messages]) => {
          loadApp(`${domain}`)
            .then((app: any) => {
              if (
                'onMessages' in app.default &&
                typeof app.default.onMessages === 'function'
              ) {
                app.default.onMessages(messages, { context });
              } else {
                console.warn(
                  `${domain}-app does not have an onMessages function`,
                );
              }
            })
            .catch((e) => {
              console.error(`Failed to load ${domain}(-app)`, e);
            });
        });
      }
    });

    return () => dispose();
  }, [webSocketConnection]);

  return <></>;
}
