import { Chat, ChatMessage, ChatState } from '@reloaded/chatbot';
import { Container, Flex, SpeechBubble } from '@reloaded/core-components';
import * as React from 'react';
import { useEffect, useState } from 'react';

export interface ConversationAreaProps {
  chat: Chat;
}

export function ConversationArea({ chat }: ConversationAreaProps) {
  const [pendingResponse, setPendingResponse] = useState(false);
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>(
    chat.chatHistory
  );

  useEffect(() => {
    const { close: closeStateChangeCallback } = chat.onChatStateChanged(
      ({ newState }) => {
        setPendingResponse(newState === ChatState.LOADING);
      }
    );

    const { close: closeChatHistoryCallback } = chat.onChatHistoryUpdated(
      (history) => {
        setChatHistory(history);
      }
    );

    return () => {
      closeStateChangeCallback();
      closeChatHistoryCallback();
    };
  }, [chat]);

  return (
    <Flex
      style={{ padding: 'var(--r-padding-1)' }}
      flexDirection="column"
      gap="var(--r-spacing-1)"
    >
      {chatHistory
        .filter((message) => message.role !== 'system')
        .map((message, index) => (
          <SpeechBubble
            key={`chat-message-${index}`}
            position={
              message.role === 'assistant'
                ? SpeechBubble.Position.LEFT
                : SpeechBubble.Position.RIGHT
            }
          >
            {message.content}
          </SpeechBubble>
        ))}

      {pendingResponse ? 'Väntar på svar...' : undefined}
    </Flex>
  );
}
