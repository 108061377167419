import { App, AppEntry } from '@reloaded/core-ui';
import { importRemote } from 'module-federation-import-remote';
import { Route } from 'react-router-dom';
import * as React from 'react';
import { getAppUri } from '../app/getAppUri';

const PROJECT_OPENING_APP_KEY = 'projectOpeningApp';
export function LoadProjectOpeningApp() {
  return (
    <AppEntry
      path="/project-openings"
      loadingFallback={<div>Loading...</div>}
      load={() =>
        importRemote({
          url: getAppUri(PROJECT_OPENING_APP_KEY, '/apps/project-opening-app'),
          scope: PROJECT_OPENING_APP_KEY,
          module: PROJECT_OPENING_APP_KEY,
          remoteEntryFileName: 'remoteEntry.js',
        })
          .then((module: unknown) => {
            // eslint-disable-next-line no-console
            console.log(`Completed loading ${PROJECT_OPENING_APP_KEY}`);

            const { default: app } = module as { default: App };

            return app;
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.error(
              `Got an error when loading ${PROJECT_OPENING_APP_KEY}: ${e}`,
            );

            return Promise.reject(e);
          })
      }
    />
  );
}
export function ProjectOpeningRoutes() {
  return (
    <Route path={'/project-openings/*'} element={LoadProjectOpeningApp()} />
  );
}
