import { useContentBundle } from '@reloaded/content-management';
import {
  Card,
  Flex,
  FormLayout,
  Grid,
  GridPage,
  Icon,
  List,
  Text,
} from '@reloaded/core-components';
import { useAdministrator } from '@reloaded/integration';
import { useAuthentication } from '@reloaded/login-app';
import * as React from 'react';
import { MyProfilePicture } from '../../components/MyProfilePicture';
import { ExampleReport } from './ExampleReport';
import { Completion } from '@reloaded/reloaded-ui';
import { DateTime } from 'luxon';

export function DashboardStartPage() {
  const contentBundle = useContentBundle('frontend.dashboard');

  const {
    authorityResourceName,
    identity: { firstName, lastName },
  } = useAuthentication();

  const [, , administratorIdAsString] = authorityResourceName.split(':');

  const administrator = useAdministrator(parseInt(administratorIdAsString, 10));

  const now = React.useMemo(() => DateTime.now(), []);

  return (
    <Flex
      flexDirection="column"
      gap="var(--r-spacing-2)"
      style={{ maxWidth: '100%', overflowX: 'hidden' }}
    >
      <Card style={{ padding: 'var(--r-padding-9)' }}>
        <Grid
          columns={['min-content', 'auto']}
          gap="var(--r-spacing-2)"
          alignItems="center"
        >
          <MyProfilePicture size="large" />
          <Flex flexDirection="column" gap="var(--r-spacing-1)">
            <Completion
              topic="r:frontend:login-welcome-message"
              context={{
                firstName,
                lastName,
                timeOfDay: now.toFormat('HH:mm'),
                dayOfWeek: now.toFormat('EEEE'),
                month: now.toFormat('MMMM'),
                date: now.toFormat('yyyy-MM-dd'),
              }}
            />
          </Flex>
        </Grid>
      </Card>
      <GridPage>
        <Card
          style={{ padding: 'var(--r-padding-9)' }}
          variant={Card.Variant.NORMAL}
        >
          <Flex flexDirection="column" gap="var(--r-spacing-3)">
            <Text
              fontSize={Text.FontSize.LARGE}
              fontWeight={Text.FontWeight.STRONG}
            >
              Funktioner att hålla koll på
            </Text>

            <List density={List.Density.SPARSE}>
              <List.Item>
                <Flex alignItems="center" gap="var(--r-spacing-2)">
                  <Icon name="info" />
                  <Text>Testa intervjuformulär på en konsultprofil!</Text>
                </Flex>
              </List.Item>
              <List.Item>
                <Flex alignItems="center" gap="var(--r-spacing-2)">
                  <Icon name="info" />
                  <Text>
                    "Yrkesrelaterat" är funktionskomplett - testa på en
                    konsultprofil.
                  </Text>
                </Flex>
              </List.Item>
              <List.Item>
                <Flex alignItems="center" gap="var(--r-spacing-2)">
                  <Icon name="info" />
                  <Text>
                    Du kan nu använda smartsöken för att hitta konsulter. Din
                    sökhistorik kommer också sparas och visas.
                  </Text>
                </Flex>
              </List.Item>
            </List>
          </Flex>
        </Card>
        <Card style={{ minHeight: '300px' }}>
          <ExampleReport />
        </Card>
        <Card
          style={{ padding: 'var(--r-padding-9)' }}
          variant={Card.Variant.NORMAL}
        >
          <FormLayout>
            <FormLayout.Field label="Tidrapporter att jaga">
              <Text
                fontSize={Text.FontSize.XLARGE}
                fontWeight={Text.FontWeight.BOLD}
              >
                0
              </Text>
            </FormLayout.Field>
            <FormLayout.Field label="Bokningsbekräftelser">
              <Text
                fontSize={Text.FontSize.XLARGE}
                fontWeight={Text.FontWeight.BOLD}
              >
                0
              </Text>
            </FormLayout.Field>
            <FormLayout.Field label="Beställningar">
              <Text
                fontSize={Text.FontSize.XLARGE}
                fontWeight={Text.FontWeight.BOLD}
              >
                0
              </Text>
            </FormLayout.Field>
            <FormLayout.Field label="Konsulter att kontakta">
              <Text
                fontSize={Text.FontSize.XLARGE}
                fontWeight={Text.FontWeight.BOLD}
              >
                0
              </Text>
            </FormLayout.Field>
            <FormLayout.Field label="Uppföljningar">
              <Text
                fontSize={Text.FontSize.XLARGE}
                fontWeight={Text.FontWeight.BOLD}
              >
                0
              </Text>
            </FormLayout.Field>
          </FormLayout>
        </Card>
      </GridPage>
    </Flex>
  );
}
