import { ContactCard } from '@reloaded/reloaded-ui';
import React from 'react';

function ContactCardShowcase() {
  return (
    <ContactCard
      title="Göteborgs Kyrkliga Stadsmission-Stigberget, skyddat boende (SoL 7.1.1 Missbruk), Göteborgs Kyrkliga Stadsmission bistånd och entrep"
      subtitle="Terminator"
      phoneNumber="4612345678"
      email="hasta_la_vista@baby.org"
    />
  );
}

export default ContactCardShowcase;
