import * as React from 'react';
import './LoadingScreen.scss';

export const LoadingScreen = () => {
  return (
    <div className="r-loading-screen-wrapper">
      <div className="r-loading-screen"></div>
    </div>
  );
};
