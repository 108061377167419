import * as React from 'react';
import { Dialog } from '@reloaded/core-components';
import Draggable from 'react-draggable';

export default function PersonalizationDialog({ requestClose }: { requestClose: () => void }) {


  return (
    <Dialog open onOpenChange={(open) => {
      if (!open) {
        requestClose();
      }
    }}>
      <Dialog.Portal>
        <div style={{width: '100%', height: '100%'}}>
          <Draggable>
            <div style={{width: 'fit-content', height: 'fit-content'}}>
              <Dialog.Content>
                <Dialog.Header heading="Personifiera ost" />
                <Dialog.Body>
                  Personifera ost personifiera ost
                </Dialog.Body>
              </Dialog.Content>
            </div>
          </Draggable>
        </div>
      </Dialog.Portal>
    </Dialog>
  );
}