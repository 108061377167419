import { Frame, Input } from '@reloaded/core-components';
import * as React from 'react';
import { Chat, ChatMessage } from '@reloaded/chatbot';
import { useMemo } from 'react';
import { ConversationArea } from './ConversationArea';
import { ChatInput } from './ChatInput';
import { ContextDataCacheEntry, useContextData } from './context-data';
import { useLocation } from 'react-router-dom';

export interface ChatFromProps {}

function splitMessageIfNecessary(
  message: ChatMessage,
  maxLength: number,
  continuationMarker: string,
) {
  if (message.content.length <= maxLength) {
    return [message];
  }

  const messages: ChatMessage[] = [];

  let { content } = message;

  while (content.length > maxLength) {
    const head = `${content.substring(
      0,
      maxLength - continuationMarker.length,
    )}${continuationMarker}`;
    const tail = content.substring(maxLength);

    messages.push({ role: message.role, content: head });

    content = tail;
  }

  if (content.length > 0) {
    messages.push({ role: message.role, content });
  }

  return messages;
}

function createInitialChatHistory(
  contextData: ContextDataCacheEntry[],
  location: string,
): ChatMessage[] {
  const keys = Object.keys(contextData);
  const explanationMessage: ChatMessage = {
    role: 'system',
    content: `Jag använder mig av ett system för bemanning av konsulter inom vård och omsorg. Jag befinner mig närvarande på ${location} i systemets frontend. Nedan följer ${keys.length} meddelanden med svar från olika APIer som anropats, på formatet "path" "response". Om "response" är för långt kommer jag avsluta med "[...]" och sen fortsätta. Jag vill att du behåller dom meddelandena och kan referera till datat i konversationen som följer efter att datat presenterats.`,
  };

  return [
    explanationMessage,
    ...contextData
      .map(
        ({ url, response }) =>
          ({
            role: 'system',
            content: `${url} => ${JSON.stringify(response)}`,
          }) as ChatMessage,
      )
      .flatMap((message) => splitMessageIfNecessary(message, 1024, '[...]'))
      .slice(0, 20),
  ];
}

export function ChatFrame({}: ChatFromProps) {
  const contextData = useContextData();
  const location = useLocation();
  const chat = useMemo(() => {
    return new Chat({
      initialChatHistory: createInitialChatHistory(
        contextData,
        `${location.pathname}${location.search ? `?${location.search}` : ''}`,
      ),
    });
  }, [contextData, location]);

  return (
    <Frame style={{ maxWidth: '500px' }}>
      <Frame.Body>
        <ConversationArea chat={chat} />
      </Frame.Body>
      <Frame.Footer>
        <ChatInput
          onChatMessage={(message) => {
            chat.say([{ role: 'user', content: message }]);
          }}
        />
      </Frame.Footer>
    </Frame>
  );
}
