import * as React from 'react';
import { createRoot } from 'react-dom/client';

import { AppEvents, DirectEventManager } from '@reloaded/core-components';
import {
  AppContext,
  AppContextManager,
  NavigationMenuContext,
  NavigationMenuContextManager,
} from '@reloaded/core-ui';

import { ApiObserver, ErrorBoundary } from '@reloaded/reloaded-ui';

import { WithCachePurge } from '@reloaded/content-management';
import {
  IndexdbCacheStorageConfiguration,
  SharedResourceCacheBackgroundUpdater,
  SharedResourceCacheConfiguration,
} from '@reloaded/integration';
import {
  AuthenticationStorage,
  defaultLoginFlow,
  LoginApi,
  LoginBoundary,
  SessionAuthenticationStorage,
  SessionStorageLoginExchange,
} from '@reloaded/login-app';
import { BrowserRouter as Router } from 'react-router-dom';
import { LoadEntitlementsOnLogin } from './app/LoadEntitlementsOnLogin';
import { ThemedApp } from './app/ThemedApp';
import { AppProviders } from './apps';
import { ApplicationLayout } from './components/ApplicationLayout';
import { IntegrationConfig } from './config';
import { configurations } from './configuration';
import WebSocketConnection from './configuration/WebSocketConnection';
import { DomainDataProvider } from './data/DomainDataProvider';
import ComponentDecorators from './decorators/ComponentDecorators';
import { ErrorCategory } from './error';
import ErrorPage from './error/pages/ErrorPage';
import { EventHandlers } from './events/EventHandlers';
import { PushMessageRouter } from './notifications/PushMessageRouter';
import { RegisterNotificationHandlers } from './notifications/RegisterNotificationHandlers';
import { PortalOutlets } from './outlets/PortalOutlets';
import { WithEmptyCachesOnNewSession } from './temp/WithEmptyCachesOnNewSession';
import { LoadingScreen } from './app/LoadingScreen';

configurations.forEach((configuration) => configuration());

const container = document.getElementById('reloaded-root');
const root = createRoot(container);

class App extends React.Component<{}, { loading: boolean; error?: Error }> {
  constructor(props: {}) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error) {
    this.setState({ error, loading: false });
  }

  render() {
    if (this.state.error) {
      return (
        <ErrorPage category={ErrorCategory.LOGIN} error={this.state.error} />
      );
    }

    return (
      <>
        {this.state.loading && <LoadingScreen />}
        {this.state.error && <div>ERROR</div>}
        <WithEmptyCachesOnNewSession>
          <IntegrationConfig enableRerouting>
            <AppEvents using={new DirectEventManager()}>
              <IndexdbCacheStorageConfiguration
                databaseName="reloaded"
                objectStoreName="cache"
                version={1}
              >
                <SharedResourceCacheConfiguration>
                  <ErrorBoundary
                    fallback={(error) => (
                      <ErrorPage category={ErrorCategory.LOGIN} error={error} />
                    )}
                  >
                    <SharedResourceCacheBackgroundUpdater
                      maxAge={1 * 3600 * 1000}
                      refreshDelay={2000}
                    />
                    <LoadEntitlementsOnLogin>
                      <ErrorBoundary
                        fallback={(error) => <ErrorPage error={error} />}
                      >
                        <LoginBoundary
                          onLogin={(auth) => {
                            this.setState({ loading: false });
                          }}
                          loginFlow={defaultLoginFlow}
                          loginContext={{
                            navigate: (url: string) =>
                              (window.location.href = url),
                            authenticationStorage:
                              new SessionAuthenticationStorage(
                                'reloaded.auth.key',
                              ),
                            loginExchange: new SessionStorageLoginExchange(
                              'reloaded.login.exchange.key',
                            ),
                            loginApi: LoginApi,
                            getAdditionalAuthenticationStorage(
                              contextKey: string,
                            ): AuthenticationStorage {
                              return new SessionAuthenticationStorage(
                                contextKey,
                              );
                            },
                          }}
                        >
                          <ThemedApp>
                            <ComponentDecorators>
                              <WebSocketConnection>
                                <AppContext.Provider
                                  value={new AppContextManager()}
                                >
                                  <ApiObserver />
                                  <WithCachePurge>
                                    <NavigationMenuContext.Provider
                                      value={new NavigationMenuContextManager()}
                                    >
                                      <ErrorBoundary
                                        fallback={(error) => (
                                          <ErrorPage
                                            category={ErrorCategory.GENERAL}
                                            error={error}
                                          />
                                        )}
                                      >
                                        <Router>
                                          <DomainDataProvider>
                                            <AppProviders>
                                              <ApplicationLayout
                                                providers={
                                                  <>
                                                    <PushMessageRouter />
                                                    <RegisterNotificationHandlers />
                                                    <EventHandlers />
                                                    <PortalOutlets />
                                                  </>
                                                }
                                              />
                                            </AppProviders>
                                          </DomainDataProvider>
                                        </Router>
                                      </ErrorBoundary>
                                    </NavigationMenuContext.Provider>
                                  </WithCachePurge>
                                </AppContext.Provider>
                              </WebSocketConnection>
                            </ComponentDecorators>
                          </ThemedApp>
                        </LoginBoundary>
                      </ErrorBoundary>
                    </LoadEntitlementsOnLogin>
                  </ErrorBoundary>
                </SharedResourceCacheConfiguration>
              </IndexdbCacheStorageConfiguration>
            </AppEvents>
          </IntegrationConfig>
        </WithEmptyCachesOnNewSession>
      </>
    );
  }
}

root.render(<App />);
