import { importRemote } from 'module-federation-import-remote';
import { getAppUri } from '../app/getAppUri';

export function loadApp(app: string) {
  // snake case to camel case
  const appNamePrefix = app.replace(/-([a-z])/g, (g) => g[1].toUpperCase());

  return importRemote({
    url: getAppUri(`${appNamePrefix}App`, `/apps/${app}-app`),
    scope: `${appNamePrefix}App`,
    module: `${appNamePrefix}App`,
    remoteEntryFileName: 'remoteEntry.js',
  });
}
