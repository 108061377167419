import {
  Accordion,
  Button,
  Flex,
  FlipForm,
  FormLayout,
  Icon,
  Input,
  MiniForm,
  Text,
  Dropdown,
  QueryHandler,
  Select,
} from '@reloaded/core-components';
import * as React from 'react';
import { ChangeEvent } from 'react';

interface Person {
  id: number;
  name: string;
}

const persons: Person[] = [
  {
    id: 1,
    name: 'Mahatma Gandhi',
  },
  {
    id: 2,
    name: 'Nelson Mandela',
  },
  {
    id: 3,
    name: 'Martin Luther King',
  },
  {
    id: 4,
    name: 'Abraham Lincoln',
  },
  {
    id: 5,
    name: 'Mother Teresa',
  },
];

const personQueryHandler: QueryHandler<Person> = async ({
  term,
  offset,
  count,
}) =>
  persons
    .filter(
      (person) =>
        !term || person.name.toLowerCase().includes(term.toLowerCase())
    )
    .slice(offset, offset + count);

export function LayoutShowcase() {
  const [formFlipped, setFormFlipped] = React.useState(false);
  const [mandatoryValue, setMandatoryValue] = React.useState('');

  return (
    <Flex
      flexDirection="column"
      style={{ padding: 'var(--r-padding-5)', gap: 'var(--r-spacing-3)' }}
    >
      <Text fontSize={Text.FontSize.XLARGE} fontWeight={Text.FontWeight.BOLD}>
        Live form
      </Text>

      <Text
        fontSize={Text.FontSize.MEDIUM}
        fontWeight={Text.FontWeight.REGULAR}
      >
        This is a live form. It is a form that is rendered in the dashboard, but
        is actually a live form that is rendered in the editor. This means that
        you can edit the form in the editor and see the changes in the dashboard
        immediately.
      </Text>

      <Text fontSize={Text.FontSize.LARGE} fontWeight={Text.FontWeight.BOLD}>
        Exempel 1
      </Text>

      <Text fontSize={Text.FontSize.MEDIUM}>
        Live-form består i sin enklaste form av en eller flera rader. Varje rad
        täcker hela bredden och har en höjd som är 1 * N, d.v.s. höjden är
        alltid en multipel av en grund-höjd. Detta för att formulären ska vara
        symmetriska och se tydliga ut.
      </Text>

      <Accordion style={{ maxWidth: '300px' }}>
        <Accordion.Content>
          <FormLayout>
            <FormLayout.Row>
              <Text>Row 1</Text>
            </FormLayout.Row>
            <FormLayout.Row span={2}>
              <MiniForm>
                <MiniForm.Control label="Label 1">
                  <Input placeholder="Label 1" />
                </MiniForm.Control>
                <MiniForm.Control label="Label 2">
                  <Input placeholder="Label 2" />
                </MiniForm.Control>
                <MiniForm.Control label="Label 3">
                  <Input placeholder="Label 3" />
                </MiniForm.Control>
              </MiniForm>
            </FormLayout.Row>
            <FormLayout.Row span={2}>
              <MiniForm>
                <MiniForm.Control label="Label 1">
                  <Input placeholder="Label 1" />
                </MiniForm.Control>
                <MiniForm.Control label="Label 2">
                  <Input placeholder="Label 2" />
                </MiniForm.Control>
              </MiniForm>
            </FormLayout.Row>
          </FormLayout>
        </Accordion.Content>
      </Accordion>

      <Text fontSize={Text.FontSize.LARGE} fontWeight={Text.FontWeight.BOLD}>
        Exempel 2
      </Text>

      <Text fontSize={Text.FontSize.MEDIUM}>
        Live-forms kan innehålla en "FlipForm" som förvandlar en komponent till
        ett inline-formulär när det aktiveras.
      </Text>

      <Accordion style={{ maxWidth: '500px' }}>
        <Accordion.Content>
          <FormLayout>
            <FormLayout.Row span={2}>
              <FlipForm
                flipped={formFlipped}
                onFlipStateChange={({ flipped }) => {
                  setFormFlipped(flipped);
                  setMandatoryValue('');
                }}
              >
                <FlipForm.View>
                  <FormLayout.Button
                    icon={<Icon name="circle-plus" />}
                    onClick={() => setFormFlipped(true)}
                  >
                    Gör nått bra
                  </FormLayout.Button>
                </FlipForm.View>
                <FlipForm.Form ready={mandatoryValue.length > 0}>
                  <Flex flexDirection="row" gap="var(--r-spacing-1)">
                    <MiniForm>
                      <MiniForm.Control label="Label 1 *">
                        <Input
                          value={mandatoryValue}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setMandatoryValue(e.target.value)
                          }
                        />
                      </MiniForm.Control>
                      <MiniForm.Control label="Label 2">
                        <Input />
                      </MiniForm.Control>
                      <MiniForm.Control label="Label 3">
                        <Dropdown>
                          <Dropdown.Item>Item 1</Dropdown.Item>
                          <Dropdown.Item>Item 2</Dropdown.Item>
                          <Dropdown.Item>Item 3</Dropdown.Item>
                        </Dropdown>
                      </MiniForm.Control>
                      <MiniForm.Control label="Label 4">
                        <Select<Person, number>
                          queryItems={personQueryHandler}
                          idOf={(person) => person.id}
                          valueToString={(person) => person.name}
                          placeholder="Select person"
                          loadingIndicator={<Icon name="circle-notch" />}
                        >
                          {(person) => <span>person.name</span>}
                        </Select>
                      </MiniForm.Control>
                    </MiniForm>
                  </Flex>
                </FlipForm.Form>
              </FlipForm>
            </FormLayout.Row>
          </FormLayout>
        </Accordion.Content>
      </Accordion>
    </Flex>
  );
}
