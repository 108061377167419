import * as React from 'react';
import {
  CalendarPeriod,
  Flex,
  Text,
  Week,
  PeriodGrid,
} from '@reloaded/core-components';
import { DateTime } from 'luxon';

export function WeekCalendarShowcase() {
  const [selectedPeriods, setSelectedPeriods] = React.useState<
    CalendarPeriod[]
  >([]);

  return (
    <Flex flexDirection="column" gap="var(--r-spacing-2)">
      <Text fontSize={Text.FontSize.XLARGE} fontWeight={Text.FontWeight.BOLD}>
        Week picker
      </Text>

      <Text fontSize={Text.FontSize.LARGE} fontWeight={Text.FontWeight.STRONG}>
        Med "lanes"
      </Text>

      <PeriodGrid
        periodType="weeks"
        selectedPeriods={selectedPeriods}
        currentPeriod={{ year: 2024, weekNumber: 1 } as Week}
        style={{ width: '400px', height: '400px' }}
        onSelectedPeriodsChanged={({ periods }) => {
          setSelectedPeriods(periods);
        }}
        lanes={[
          {
            fromDate: DateTime.fromObject({ year: 2024, month: 1, day: 10 }),
            toDate: DateTime.fromObject({ year: 2024, month: 1, day: 24 }),
          },
          {
            fromDate: DateTime.fromObject({ year: 2024, month: 1, day: 22 }),
            toDate: DateTime.fromObject({ year: 2024, month: 2, day: 14 }),
          },
        ]}
        renderTooltipContent={(lane) => (
          <div>
            This is the tooltip for lane {lane.fromDate.toFormat('yyyy-MM-dd')}-
            {lane.toDate.toFormat('yyyy-MM-dd')}
          </div>
        )}
      />
    </Flex>
  );
}
