import { ContentBundle } from '@reloaded/content-management';
import { Icon, Sidebar } from '@reloaded/core-components';
import { Authorized } from '@reloaded/login-app';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApplicationRoute from './ApplicationRoute';
import ProjectOpeningMenu from './ProjectOpeningMenu';
import SettingsMenu from './SettingsMenu';

export interface ApplicationSidebarProps
  extends Omit<React.ComponentProps<typeof Sidebar>, 'children'> {
  content: ContentBundle;
  contentPath: string;
}

export function ApplicationSidebar({
  content,
  contentPath,
  ...props
}: ApplicationSidebarProps) {
  const navigate = useNavigate();
  const [selectedRoute, setSelectedRoute] = useState(ApplicationRoute.HOME);
  const handleRouteChange = (
    route: string,
    item: (typeof ApplicationRoute)[keyof typeof ApplicationRoute],
  ) => {
    setSelectedRoute(item);
    navigate(route);
  };

  return (
    <Sidebar {...props}>
      <Sidebar.Item
        selected={selectedRoute === ApplicationRoute.HOME}
        onClick={() => handleRouteChange('/', ApplicationRoute.HOME)}
      >
        <i className="r-icon-at" />
        <p>
          {content.get(`${contentPath}.home`, {
            defaultValue: 'Hem',
          })}
        </p>
      </Sidebar.Item>

      <Sidebar.Item
        selected={selectedRoute === ApplicationRoute.CLIENT_PROFILES}
        onClick={() =>
          handleRouteChange(
            '/client-profiles',
            ApplicationRoute.CLIENT_PROFILES,
          )
        }
      >
        <i className="r-icon-house" />
        <p>
          {content.get(`${contentPath}.clientprofiles`, {
            defaultValue: 'UG',
          })}
        </p>
      </Sidebar.Item>

      <Sidebar.Item
        selected={selectedRoute === ApplicationRoute.CONSULTANT_PROFILES}
        onClick={() =>
          handleRouteChange(
            '/consultants',
            ApplicationRoute.CONSULTANT_PROFILES,
          )
        }
      >
        <i className="r-icon-profile" />
        <p>
          {content.get(`${contentPath}.consultantprofiles`, {
            defaultValue: 'Konsulter',
          })}
        </p>
      </Sidebar.Item>

      <Sidebar.Item
        selected={selectedRoute === ApplicationRoute.BOOKINGS}
        onClick={() =>
          handleRouteChange('/bookings', ApplicationRoute.BOOKINGS)
        }
      >
        <i className="r-icon-checkmark" />
        <p>
          {content.get(`${contentPath}.bookings`, {
            defaultValue: 'Bokningar',
          })}
        </p>
      </Sidebar.Item>

      <Sidebar.Item
        selected={selectedRoute === ApplicationRoute.AVAILABILITY}
        onClick={() =>
          handleRouteChange('/calendar', ApplicationRoute.AVAILABILITY)
        }
        disabled
      >
        <i className="r-icon-calendar" />
        <p>
          {content.get(`${contentPath}.availability`, {
            defaultValue: 'Tillgänglighet',
          })}
        </p>
      </Sidebar.Item>

      <Sidebar.Item
        selected={selectedRoute === ApplicationRoute.AGREEMENT}
        onClick={() =>
          handleRouteChange('/agreements', ApplicationRoute.AGREEMENT)
        }
      >
        <i className="r-icon-note" />
        <p>
          {content.get(`${contentPath}.agreement`, {
            defaultValue: 'Avtal',
          })}
        </p>
      </Sidebar.Item>

      <Sidebar.Item
        selected={selectedRoute === ApplicationRoute.STATUS}
        onClick={() => handleRouteChange('/status', ApplicationRoute.STATUS)}
        disabled
      >
        <i className="r-icon-stats" />
        <p>
          {content.get(`${contentPath}.status`, {
            defaultValue: 'Status',
          })}
        </p>
      </Sidebar.Item>

      <Sidebar.Item
        selected={selectedRoute === ApplicationRoute.PRESENTATIONS}
        onClick={() =>
          handleRouteChange('/presentations', ApplicationRoute.PRESENTATIONS)
        }
      >
        <i className="r-icon-popup" />
        <p>
          {content.get(`${contentPath}.presentations`, {
            defaultValue: 'Presentationer',
          })}
        </p>
      </Sidebar.Item>

      <ProjectOpeningMenu
        contentPath={contentPath}
        selectedRoute={selectedRoute}
        onRouteChanged={({ path, route }) => handleRouteChange(path, route)}
      />

      <Sidebar.Item
        selected={selectedRoute === ApplicationRoute.ORDERS}
        onClick={() => handleRouteChange('/orders', ApplicationRoute.ORDERS)}
        disabled
      >
        <i className="r-icon-heart-hand" />
        <p>
          {content.get(`${contentPath}.orders`, {
            defaultValue: 'Beställningar',
          })}
        </p>
      </Sidebar.Item>

      <Authorized onlyIf="a:w">
        <Sidebar.Item
          selected={selectedRoute === ApplicationRoute.ADMIN}
          onClick={() =>
            handleRouteChange(
              '/settings/administrators',
              ApplicationRoute.ADMIN,
            )
          }
        >
          <i className="r-icon-question-mark" />
          <p>
            {content.get(`${contentPath}.admin`, {
              defaultValue: 'Handläggare',
            })}
          </p>
        </Sidebar.Item>
      </Authorized>

      <Authorized anyOf={['ai:w', 'ai:r', 'ai:*']}>
        <Sidebar.Item
          selected={selectedRoute === ApplicationRoute.AI}
          onClick={() => handleRouteChange('/ai', ApplicationRoute.AI)}
        >
          <Icon name="ai-generate" />
          <p>
            {content.get(`${contentPath}.ai`, {
              defaultValue: 'AI',
            })}
          </p>
        </Sidebar.Item>
      </Authorized>

      <SettingsMenu
        selectedRoute={selectedRoute}
        onRouteChanged={({ path, route }) => handleRouteChange(path, route)}
      />
    </Sidebar>
  );
}
