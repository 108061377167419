import { Badge, Tabs } from '@reloaded/core-components';
import React from 'react';

function TabsShowcase() {
  return (
    <Tabs>
      <Tabs.List>
        <Tabs.Trigger value="1" title={'Tab 1'} badge={<Badge label="1" />} />
        <Tabs.Trigger value="2" title={'Tab 2'} />
        <Tabs.Trigger value="3" title={'Tab 3'} />
      </Tabs.List>
      <Tabs.Content value="1">Tab 1 content</Tabs.Content>
      <Tabs.Content value="2">Tab 2 content</Tabs.Content>
      <Tabs.Content value="3">Tab 3 content</Tabs.Content>
    </Tabs>
  );
}

export default TabsShowcase;
