import { App, AppEntry } from '@reloaded/core-ui';
import { importRemote } from 'module-federation-import-remote';
import { Route } from 'react-router-dom';

import * as React from 'react';
import { getAppUri } from '../app/getAppUri';

export function loadAiApp() {
  return importRemote({
    url: getAppUri('aiApp', '/apps/ai-app'),
    scope: 'aiApp',
    module: 'aiApp',
    remoteEntryFileName: 'remoteEntry.js',
  });
}

export const routes = () => (
  <Route
    path="/ai/*"
    element={
      <AppEntry
        path="/ai"
        loadingFallback={<div>Loading...</div>}
        load={() =>
          loadAiApp()
            .then((module: any) => {
              console.log('Completed loading ai app');
              return module.default as App;
            })
            .catch((e) => {
              console.error('Got an error when loading AI app: ' + e);

              return Promise.reject(e);
            })
        }
      />
    }
  />
);
