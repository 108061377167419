import * as React from 'react';
import { useCurrentAdministrator } from '@reloaded/reloaded-ui';

const allowedUserEmails = [
  'oscar.andren@agila.se',
  'andreas.riling@agila.se',
  'antonio.morales@agila.se',
  'erik.akerberg@agila.se',
  'nils.horn@agila.se',
  'madelene.schander@agila.se',
  'andreas.nilsson@agila.se',
];
export function useDevUser(): {
  isDevUser: boolean;
  loading: boolean;
  error: Error;
} {
  const { administrator, loading, error } = useCurrentAdministrator();
  const isDevUser =
    allowedUserEmails.includes(administrator?.emailAddress) ||
    administrator?.emailAddress.endsWith('@test.se');
  return { isDevUser, loading, error };
}
