import * as React from 'react';
import { ReactNode, useContext, useEffect, useState } from 'react';

import {
  Annotation,
  Avatar,
  Button,
  Divider,
  Flex,
  Icon,
  IconButton,
  InteractiveTooltip,
  MiniCard,
  Select,
  QueryHandler as SelectQueryHandler,
} from '@reloaded/core-components';

import {
  Administrator,
  AdministratorApi,
  AdministratorFilterProperty,
  AdministratorSortProperty,
  SortDirection,
} from '@reloaded/integration';
import {
  Authentication,
  Authorized,
  LoginConfigurationContext,
  createImpersonationLoginFlow,
  createReturnToImpersonatorLoginFlow,
  logout,
  useAuthentication,
  useLoginContext,
} from '@reloaded/login-app';
import { FilterType, RSQLSingleSearch, rsql } from '@reloaded/rsql';
import { MyProfilePicture } from './MyProfilePicture';
const AUTHORITY_RESOURCE_NAME_PATTERN =
  /r:administrator:(?<administratorId>\d+)/;

type DepartmentName = 'doctor' | 'nurse' | 'socialworker';

const DepartmentIcons: { [departmentId: number]: DepartmentName } = {
  1: 'doctor',
  2: 'nurse',
  5: 'socialworker',
};

const administratorsLoader: SelectQueryHandler<Administrator> = ({
  term,
  offset,
  count,
}) =>
  AdministratorApi.searchAdministrators(
    {
      filter: term
        ? rsql({
            type: FilterType.SINGLE,
            operator: RSQLSingleSearch.LIKE,
            key: AdministratorFilterProperty.FIRST_NAME,
            value: `${encodeURIComponent(`${term}%`)}`,
          })
        : '',

      offset,
      count,
      sort: [
        {
          property: AdministratorSortProperty.FIRST_NAME,
          direction: SortDirection.ASCENDING,
        },
        {
          property: AdministratorSortProperty.LAST_NAME,
          direction: SortDirection.ASCENDING,
        },
      ],
    },
    'teams',
  ).then((administrators) => administrators);

function ImpersonationAdministratorDropdown() {
  const [administrator, setAdministrator] = useState<Administrator>();

  const [dropdownExpanded, setDropdownExpanded] = useState<boolean>(false);
  const loginConfiguration = useContext(LoginConfigurationContext);

  return (
    <Select<Administrator, number>
      expanded={dropdownExpanded}
      onExpandedChanged={setDropdownExpanded}
      placeholder="Välj användare"
      queryItems={administratorsLoader}
      value={administrator}
      onValueChanged={({ value, previousValue }) => {
        setAdministrator(value);
        setDropdownExpanded(false);
        loginConfiguration.changeLoginFlow(
          createImpersonationLoginFlow(value.userId),
        );
      }}
      idOf={(administrator) => administrator.id}
      valueToString={(admin) => `${admin.firstName} ${admin.lastName}`}
    >
      {(a) => (
        <Annotation
          value={
            <Flex
              flexDirection="row"
              gap="var(--r-spacing-1)"
              alignItems="center"
            >
              {a.teams
                .map((team) => <span>{team.name}</span>)
                .reduce(
                  (acc, next) => (acc ? [...acc, <Divider />, next] : [next]),
                  null as ReactNode[],
                )}
              <Icon
                style={{ width: '2em', height: '2em' }}
                name={DepartmentIcons[a.departmentId]}
              />
            </Flex>
          }
        >
          {a.firstName} {a.lastName}
        </Annotation>
      )}
    </Select>
  );
}
interface ReturnToImpersonatorProps {
  impersonator: Administrator;
}
function ReturnToImpersonator({ impersonator }: ReturnToImpersonatorProps) {
  const loginConfiguration = useContext(LoginConfigurationContext);

  if (!impersonator) {
    return undefined;
  }

  const profilePictureId =
    impersonator.profilePictures?.smallProfilePictureId ||
    impersonator.profilePictures?.largeContactPictureId;

  function returnToImpersonator() {
    loginConfiguration.changeLoginFlow(createReturnToImpersonatorLoginFlow());
  }

  return (
    <>
      <Divider horizontal />

      <div>Ursprungligen inloggad som:</div>
      <MiniCard
        density="spacious"
        iconSize="large"
        icon={
          profilePictureId && (
            <Avatar
              image={`/file-api/v1/files/${profilePictureId}/data`}
              size={Avatar.Size.MEDIUM}
              type={Avatar.Type.AVATAR_INTERNAL}
            />
          )
        }
        title={
          <Annotation
            value={
              <IconButton onClick={returnToImpersonator}>
                <Icon name="undo" />
              </IconButton>
            }
          >
            {impersonator.firstName} {impersonator.lastName}
          </Annotation>
        }
      />
    </>
  );
}
interface MyProfileDropdownMenuProps {
  impersonator: Administrator;
}
function MyProfileDropdownMenu({ impersonator }: MyProfileDropdownMenuProps) {
  const {
    authorityResourceName,
    identity: { firstName, lastName },
  } = useAuthentication();

  const [administrator, setAdministrator] = useState<Administrator>(null);

  useEffect(() => {
    const match = AUTHORITY_RESOURCE_NAME_PATTERN.exec(authorityResourceName);

    if (match) {
      const administratorId = parseInt(match.groups.administratorId, 10);

      AdministratorApi.getAdministrator(administratorId).then(setAdministrator);
    }
  }, [authorityResourceName]);

  return (
    <Flex
      flexDirection="column"
      gap="var(--r-spacing-1)"
      style={{
        width: 'max-content',
        height: 'max-content',
        padding: 'var(--r-spacing-3)',
      }}
    >
      <MiniCard
        icon={
          <MyProfilePicture
            size="large"
            type={impersonator ? 'impersonator' : 'self'}
          />
        }
        title={`${firstName} ${lastName}`}
        subTitle={administrator && administrator.jobTitle}
        density="spacious"
      />

      <Authorized onlyIf="l:imp">
        <Divider horizontal />
        <div>Logga in som</div>
        <ImpersonationAdministratorDropdown />
      </Authorized>

      <ReturnToImpersonator impersonator={impersonator} />
    </Flex>
  );
}

export interface MyProfileButtonProps {}

export function MyProfileButton(props: MyProfileButtonProps) {
  const [menuVisible, setMenuVisible] = React.useState(false);

  const loginContext = useLoginContext();
  const authentication = useAuthentication();
  const impersonatorAuthenticationStorage =
    loginContext.getAdditionalAuthenticationStorage('impersonator');
  const impersonatorAuthentication: Authentication =
    impersonatorAuthenticationStorage.get();

  const [error, setError] = useState<Error>(null);
  const [impersonator, setImpersonator] = useState<Administrator>(null);

  useEffect(() => {
    if (!impersonatorAuthentication?.authorityResourceName) {
      setImpersonator(null);
      return;
    }

    const match = AUTHORITY_RESOURCE_NAME_PATTERN.exec(
      impersonatorAuthentication.authorityResourceName,
    );

    if (match) {
      const administratorId = parseInt(match.groups.administratorId, 10);

      AdministratorApi.getAdministrator(administratorId, 'profilePictures')
        .then(setImpersonator)
        .catch(setError);
    }
  }, [
    impersonatorAuthentication?.authorityResourceName,
    authentication?.token,
  ]);
  if (error) {
    throw error;
  }
  return (
    <InteractiveTooltip
      open={menuVisible}
      onOpenChange={(state) => setMenuVisible(state)}
    >
      <InteractiveTooltip.Trigger>
        <MyProfilePicture
          onClick={() => setMenuVisible(!menuVisible)}
          type={impersonator ? 'impersonator' : 'self'}
        />
      </InteractiveTooltip.Trigger>
      <InteractiveTooltip.Content>
        <MyProfileDropdownMenu impersonator={impersonator} />
      </InteractiveTooltip.Content>
      <InteractiveTooltip.Footer>
        <Flex alignContent="center" justifyContent="center">
          <Button
            size={Button.Size.LARGE}
            variant={Button.Variant.GHOST}
            onClick={() =>
              logout().then((continuation) =>
                window.location.replace(continuation.action.url),
              )
            }
          >
            Logga ut
          </Button>
        </Flex>
      </InteractiveTooltip.Footer>
    </InteractiveTooltip>
  );
}
