export interface Pet {
  id: number;
  name: string;
}

export const DropdownShowcaseTestData: Pet[] = [
  { id: 1, name: 'Buddy' },
  { id: 2, name: 'Max' },
  { id: 3, name: 'Charlie' },
  { id: 4, name: 'Rocky' },
  { id: 5, name: 'Cooper' },
  { id: 6, name: 'Bear' },
  { id: 7, name: 'Duke' },
  { id: 8, name: 'Toby' },
  { id: 9, name: 'Tucker' },
  { id: 10, name: 'Jack' },
  { id: 11, name: 'Oliver' },
  { id: 12, name: 'Teddy' },
  { id: 13, name: 'Milo' },
  { id: 14, name: 'Winston' },
  { id: 15, name: 'Zeus' },
  { id: 16, name: 'Loki' },
  { id: 17, name: 'Thor' },
  { id: 18, name: 'Apollo' },
  { id: 19, name: 'Gus' },
  { id: 20, name: 'Oscar' },
  { id: 21, name: 'Bailey' },
  { id: 22, name: 'Finn' },
  { id: 23, name: 'Harley' },
  { id: 24, name: 'Jax' },
  { id: 25, name: 'Koda' },
  { id: 26, name: 'Leo' },
  { id: 27, name: 'Moose' },
  { id: 28, name: 'Murphy' },
  { id: 29, name: 'Riley' },
  { id: 30, name: 'Roscoe' },
  { id: 31, name: 'Rusty' },
  { id: 32, name: 'Sam' },
  { id: 33, name: 'Simba' },
  { id: 34, name: 'Tank' },
  { id: 35, name: 'Walter' },
  { id: 36, name: 'Whiskey' },
  { id: 37, name: 'Yoda' },
  { id: 38, name: 'Ziggy' },
  { id: 39, name: 'Bella' },
  { id: 40, name: 'Lucy' },
  { id: 41, name: 'Daisy' },
  { id: 42, name: 'Luna' },
  { id: 43, name: 'Lola' },
  { id: 44, name: 'Sadie' },
  { id: 45, name: 'Molly' },
  { id: 46, name: 'Bailey' },
  { id: 47, name: 'Maggie' },
  { id: 48, name: 'Sophie' },
  { id: 49, name: 'Chloe' },
  { id: 50, name: 'Zoe' },
];
