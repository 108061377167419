import * as React from 'react';
import { Snackbar, useEvent } from '@reloaded/core-components';
import { ErrorPanel, ErrorEvent } from '@reloaded/reloaded-ui';
import { useContentBundle } from '@reloaded/content-management';

export default function () {
  const [errors, setErrors] = React.useState<ErrorEvent[]>([]);
  const content = useContentBundle('frontend.errors');

  useEvent<ErrorEvent>(ErrorEvent.TYPE, (e: ErrorEvent) => {
    setErrors((prev) => [...prev, e]);
  });

  console.log('ErrorSnackbarNotification::render', errors.length)

  if (errors.length === 0) {
    return undefined;
  }

  return (
    <Snackbar>
      {errors.map((e, i) => (
        <Snackbar.Item
          key={i}
          disappearAfter={5000}
          requestClose={() => {
            //setErrors((prev) => prev.filter((x) => x !== e));
            console.log('ErrorSnackbarNotification::close error')
            setErrors((prev) => {
              return [...prev.slice(0, i), ...prev.slice(i + 1)];

            });
          }}
        >
          <ErrorPanel error={e.error}>
            {content.get(e.description.messageKey, {
              defaultValue: e.description.defaultMessage,
              placeholders: e.description.values,
            })}
          </ErrorPanel>
        </Snackbar.Item>
      ))}
    </Snackbar>
  );
}
