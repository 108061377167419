import { App, AppEntry } from '@reloaded/core-ui';
import { importRemote } from 'module-federation-import-remote';
import * as React from 'react';
import { Route } from 'react-router-dom';
import { getAppUri } from '../app/getAppUri';

const CONSULTANT_APP_KEY = 'consultantApp';

export default function ConsultantRoutes() {
  return (
    <Route
      path="/consultants/*"
      element={(
        <AppEntry<App>
          path="/consultants"
          loadingFallback={<div>Loading...</div>}
          load={() => importRemote({
            url: getAppUri(CONSULTANT_APP_KEY, '/apps/consultant-app'),
            scope: CONSULTANT_APP_KEY,
            module: CONSULTANT_APP_KEY,
            remoteEntryFileName: 'remoteEntry.js',
          })
            .then((module: unknown) => {
              // eslint-disable-next-line no-console
              console.log(`Completed loading ${CONSULTANT_APP_KEY}`);

              const { default: app } = module as { default: App };

              return app;
            })
            .catch((e) => {
              // eslint-disable-next-line no-console
              console.error(`Got an error when loading ${CONSULTANT_APP_KEY}: ${e}`);

              return Promise.reject(e);
            })}
        />
      )}
    />
  );
}
