import * as React from 'react';
import {
  Flex,
  Icon,
  Input,
  Text,
  TextInput,
  TextBox,
  Actionable,
} from '@reloaded/core-components';
import {
  AmountInput, DurationInput,
  LocalizedInput,
  LocalizedString,
  PostNote,
  ValidInput
} from '@reloaded/reloaded-ui';
import { ChangeEvent } from 'react';
import { Duration } from 'luxon';

export function InputShowcase() {
  const [localizedInputValue, setLocalizedInputValue] =
    React.useState<LocalizedString>({});
  const [textBoxValue, setTextBoxValue] = React.useState(`Hej, 

Här kommer en presentation på vår arbetsterapeut Anette Lagerberg. 

Anette har arbetat som enhetschef sedan 2017 för 2 stycken enheter samt ett hemtjänstdistrikt. Anette har idag personalansvar över 37 anställda och har utöver detta verksamhetsansvar och budgetansvar samt är delaktig i flera om-organisationer inom äldreomsorgen. 

Anette har innan detta varit verksamhetschef och ansvarig för uppstarten av en hemsjukvårds enhet i Dubai där hon arbetade i 14 år.
  `);

  const [amountInputValue, setAmountInputValue] = React.useState<number>(100);
  const [amountInputCurrency, setAmountInputCurrency] =
    React.useState<string>('SEK');
  const [duration, setDuration] = React.useState<Duration>();

  return (
    <Flex
      flexDirection="column"
      gap="var(--r-spacing-1)"
      style={{ maxWidth: '300px' }}
    >
      <Text>Duration input</Text>
      <Flex flexDirection="row" gap="var(--r-spacing-1)" alignItems="center">
        <DurationInput value={duration} onChange={({value}) => setDuration(value)} />
        <Text>{duration?.toISO() ?? 'N/A'}</Text>
      </Flex>

      <Text>Localized input</Text>

      <LocalizedInput
        value={localizedInputValue}
        onChange={setLocalizedInputValue}
      />
      {Object.entries(localizedInputValue).map(([languageCode, value]) => (
        <div
          key={languageCode}
          style={{ fontSize: 'var(--r-font-size-small)' }}
        >
          {languageCode}: {value}
        </div>
      ))}

      <Text>Amount input</Text>

      <AmountInput
        value={amountInputValue}
        currency={amountInputCurrency}
        onChange={({ value, currency }) => {
          setAmountInputCurrency(currency);
          setAmountInputValue(value);
        }}
      />

      <Text>Notes</Text>

      <PostNote submitHandler={() => {}} editExisting={false} />

      {(
        <>
          <Text>Textbox with automatic height</Text>

          <Actionable>
            {(actioning) => (
              <TextBox
                fullHeight
                naked={!actioning}
                value={textBoxValue}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setTextBoxValue(e.target.value)
                }
              />
            )}
          </Actionable>

          <Text>Input with placeholder, no value</Text>
          <Input placeholder="Placeholder" />

          <Text>Input with a placeholder and a value</Text>
          <Input placeholder="Placeholder" value="Value" />

          <Text>Disabled input</Text>
          <Input placeholder="Placeholder" value="Value" disabled />

          <Text>Read-only input</Text>
          <Input placeholder="Placeholder" value="Value" readOnly />

          <Text>Input with error</Text>
          <Input placeholder="Placeholder" value="Value" erroneous />

          <Text>Input with unit</Text>
          <TextInput placeholder="Placeholder" value="Value" afterLabel="ÅR" />

          <Text>Input with icon</Text>
          <TextInput
            placeholder="Placeholder"
            value="Value"
            afterLabel={
              <Icon name="checkmark" size={Icon.Size.FONT_SIZE_HALF} />
            }
          />

          <Text fontSize={Text.FontSize.LARGE}>
            Validated input (numeric only)
          </Text>
          <Text>
            Valid inputs apply a validator every time the user types something.
            The validator is asynchronous so it can even invoke a server-side
            validation or API to apply the validation. It will maintain the
            "raw" value internally, even though it's invalid, but it will only
            announce valid values. Hence, the ValidInput will only announce
            valid values.
          </Text>

          <ValidInput
            validator={(value) => {
              if (/^\d+$/.test(value)) {
                return Promise.resolve([]);
              }

              return Promise.resolve([
                { messageKey: 'my.error', defaultMessage: 'My error' },
              ]);
            }}
            placeholder="Placeholder"
          />
        </>
      )}
    </Flex>
  );
}
