import {
  Badge,
  Button,
  Chip,
  Flex,
  Icon,
  IconButton,
  Input,
  OverflowContainer,
  Text,
  Tooltip,
} from '@reloaded/core-components';
import * as React from 'react';

function ExampleWithBadges() {
  const [items, setItems] = React.useState<string[]>([
    'Neo',
    'Morpheus',
    'Trinity',
    'Agent Smith',
  ]);
  const [newItem, setNewItem] = React.useState<string>('');
  const [overflowItems, setOverflowItems] = React.useState<HTMLElement[]>([]);
  const [tooltipVisible, setTooltipVisible] = React.useState<boolean>(false);

  return (
    <Flex flexDirection="column" gap="var(--r-spacing-1)">
      <Flex flexDirection="row" alignItems="center" gap="var(--r-spacing-1)">
        <OverflowContainer
          onOverflowChange={(e) => {
            setOverflowItems(e.overflowElements);
          }}
          style={{
            border: 'dashed var( --r-color-lighter-blue) 1px',
            minHeight: '1em',
            display: 'flex',
            flexDirection: 'row',
            gap: 'var(--r-spacing-1)',
            padding: 'var(--r-padding-1)',
            width: '250px',
          }}
        >
          {items.map((item) => (
            <Chip
              key={`item-${item}`}
              label={item}
              icon={
                <IconButton
                  background={IconButton.Background.TRANSPARENT}
                  size={IconButton.Size.XSMALL}
                  onClick={() =>
                    setItems((prevItems) => prevItems.filter((i) => i !== item))
                  }
                >
                  <Icon name="chip-remove" size={Icon.Size.XSMALL} />
                </IconButton>
              }
            />
          ))}
        </OverflowContainer>
        {overflowItems.length > 0 && (
          <Tooltip open={tooltipVisible} onOpenChange={setTooltipVisible}>
            <Tooltip.Trigger>
              <IconButton onClick={() => setTooltipVisible(true)}>
                <Badge label={`+${overflowItems.length}`} />
              </IconButton>
            </Tooltip.Trigger>
            <Tooltip.Content>
              <Flex flexDirection="column" gap="var(--r-spacing-1)">
                {overflowItems.map((item, n) => (
                  <div
                    key={`item-${n}`}
                    dangerouslySetInnerHTML={{
                      __html: (() => {
                        const clone = item.cloneNode(true) as HTMLElement;

                        clone.style.visibility = 'visible';
                        clone.style.opacity = '0.5';
                        clone.style.filter = /* gray scale */ 'grayscale(100%)';
                        clone.style.pointerEvents = 'none';

                        return clone.outerHTML;
                      })(),
                    }}
                  />
                ))}
              </Flex>
            </Tooltip.Content>
          </Tooltip>
        )}
      </Flex>
      <Flex flexDirection="row" alignItems="center" gap="var(--r-spacing-1)">
        <Input
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNewItem(e.target.value)
          }
          value={newItem}
        />
        <Button
          onClick={() => {
            setItems((prevItems) => [...prevItems, newItem]);
            setNewItem('');
          }}
        >
          Add item
        </Button>
      </Flex>
    </Flex>
  );
}

export function OverflowContainerShowcase() {
  return (
    <Flex
      flexDirection="column"
      style={{ margin: 'var(--r-spacing-2)', maxWidth: '300px' }}
      gap="var(--r-spacing-2)"
    >
      <Text fontSize={Text.FontSize.XLARGE} fontWeight={Text.FontWeight.BOLD}>
        Beskrivning
      </Text>

      <Text
        fontSize={Text.FontSize.MEDIUM}
        fontWeight={Text.FontWeight.REGULAR}
      >
        En <i>overflow container</i> används för att dölja innehåll som inte får
        plats. Komponenten berättar också för användaren när en komponent döljs
        och när den tas tillbaka. Detta är användbart för att visa t.ex. antalet
        items som inte är synliga.
      </Text>

      <Text fontSize={Text.FontSize.XLARGE} fontWeight={Text.FontWeight.BOLD}>
        Exempel 1
      </Text>

      <ExampleWithBadges />
    </Flex>
  );
}
