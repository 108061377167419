import * as React from 'react';
import { AtomicComponent, Flex, Text, Steps } from '@reloaded/core-components';
import { ReactNode } from 'react';

// It'd be nice to have this:
// <Steps.Context>...<Steps><Steps.Step name="step1" /></Steps><Steps.Content name="step1">Step 1 content</Steps.Content></Steps.Context>

//
// Showcase
//

const ExampleTitle = ({
  title,
  subTitle,
}: {
  title: string;
  subTitle: string;
}) => {
  return (
    <Flex flexDirection="column">
      <Text fontSize={Text.FontSize.LARGE} fontWeight={Text.FontWeight.STRONG}>
        {title}
      </Text>
      <Text fontSize={Text.FontSize.MEDIUM}>{subTitle}</Text>
    </Flex>
  );
};

export const StepsShowcase = () => {
  const [selectedStepName, setSelectedStepName] = React.useState('step1');

  return (
    <Steps.Context
      selectedStepName={selectedStepName}
      onSelectedStepChanged={setSelectedStepName}
    >
      <Flex
        flexDirection="row"
        gap="var(--r-spacing-4)"
        style={{ height: '500px' }}
      >
        <Steps style={{ width: '155px' }}>
          <Steps.Step name="step1" onClick={() => setSelectedStepName('step1')}>
            <ExampleTitle
              title="Grundläggande intervjufrågor"
              subTitle="7/27"
            />
          </Steps.Step>
          <Steps.Step name="step2" onClick={() => setSelectedStepName('step2')}>
            <ExampleTitle title="Personliga uppgifter" subTitle="0/27" />
          </Steps.Step>
          <Steps.Step name="step3" onClick={() => setSelectedStepName('step3')}>
            <ExampleTitle title="Yrkesrelaterat" subTitle="0/27" />
          </Steps.Step>
          <Steps.Step
            name="step4"
            onClick={() => {
              setSelectedStepName('step4');
            }}
          >
            <ExampleTitle title="Bank och företagsuppgifter" subTitle="0/27" />
          </Steps.Step>
        </Steps>
        <Flex flexDirection="column" style={{ overflow: 'auto' }}>
          <Steps.Pageable>
            <Steps.Content name="step1">
              <h1>Step 1: Introduction</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              <p>Quisque sit amet accumsan quam. Nulla facilisi.</p>
              <ul>
                <li>Item 1</li>
                <li>Item 2</li>
                <li>Item 3</li>
                <li>Item 4</li>
              </ul>
              <p>Proin in ante eget purus facilisis dictum.</p>
              <blockquote>Quote here</blockquote>
            </Steps.Content>

            <Steps.Content name="step2">
              <h1>Step 2: Details</h1>
              <p>Quisque sit amet accumsan quam.</p>
              <p>Donec nec dui vel eros efficitur aliquam.</p>
              <ol>
                <li>First</li>
                <li>Second</li>
                <li>Third</li>
                <li>Fourth</li>
              </ol>
              <p>Nulla facilisi. Proin in ante eget purus facilisis dictum.</p>
              <pre>Code snippet here</pre>
            </Steps.Content>

            <Steps.Content name="step3">
              <h1>Step 3: More Info</h1>
              <p>Nulla facilisi. Proin in ante eget purus facilisis dictum.</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              <blockquote>Quote here</blockquote>
              <blockquote>Another quote here</blockquote>
              <ul>
                <li>Item A</li>
                <li>Item B</li>
                <li>Item C</li>
                <li>Item D</li>
              </ul>
            </Steps.Content>
            <Steps.Content name="step4">
              <h1>Step 4: Conclusion</h1>
              <p>Donec nec dui vel eros efficitur aliquam.</p>
              <p>Quisque sit amet accumsan quam. Nulla facilisi.</p>
              <pre>Code snippet here</pre>
              <pre>Another code snippet here</pre>
              <ol>
                <li>Final 1</li>
                <li>Final 2</li>
                <li>Final 3</li>
                <li>Final 4</li>
              </ol>
            </Steps.Content>
          </Steps.Pageable>
        </Flex>
      </Flex>
    </Steps.Context>
  );
};
