import { LoadingIndicator } from '@reloaded/core-components';
import { App, AppEntry } from '@reloaded/core-ui';
import { importRemote } from 'module-federation-import-remote';
import * as React from 'react';
import { Route } from 'react-router-dom';
import { getAppUri } from '../app/getAppUri';

const APP_KEY = 'settingsApp';

export default function SettingsRoutes() {
  return (
    <Route
      path="/settings/*"
      element={
        <AppEntry<App>
          path="/settings"
          loadingFallback={
            <LoadingIndicator context={LoadingIndicator.Context.PAGE} />
          }
          load={() =>
            importRemote({
              url: getAppUri(APP_KEY, '/apps/settings-app'),
              scope: APP_KEY,
              module: APP_KEY,
              remoteEntryFileName: 'remoteEntry.js',
            })
              .then((module: unknown) => {
                // eslint-disable-next-line no-console
                console.log(`Completed loading ${APP_KEY}`);

                const { default: app } = module as { default: App };

                return app;
              })
              .catch((e) => {
                // eslint-disable-next-line no-console
                console.error(`Got an error when loading ${APP_KEY}: ${e}`);

                return Promise.reject(e);
              })
          }
        />
      }
    />
  );
}
