import {fetch} from "@reloaded/integration";

export const configureFetch = () => {
    fetch.override(window);

    fetch.intercept((next, requestInfo, init) => {
        const newInit: typeof init = {
            ...(init || {}),
            cache: init?.cache ?? 'no-store', // we manage the caches in the frontend app
        }

        return next(requestInfo, newInit);
    });
}