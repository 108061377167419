import * as React from 'react';

function clearDatabase(databaseName: string): Promise<void> {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(databaseName);

        request.onsuccess = function(event) {
            const db = request.result;

            // Get the list of object store names.
            const objectStores = db.objectStoreNames;

            let completedStoresCount = 0;

            for (let i = 0; i < objectStores.length; i++) {
                const storeName = objectStores[i];

                // Start a writable transaction for each object store.
                const transaction = db.transaction(storeName, "readwrite");

                // Clear all items from the current object store.
                const objectStore = transaction.objectStore(storeName);
                objectStore.clear();

                transaction.oncomplete = function() {
                    completedStoresCount++;

                    // Check if all object stores have been cleared.
                    if (completedStoresCount === objectStores.length) {
                        resolve();
                    }
                };

                transaction.onerror = function() {
                    reject(new Error(`Error clearing object store ${storeName}`));
                };
            }

            // If there are no object stores, resolve immediately.
            if (objectStores.length === 0) {
                resolve();
            }
        };

        request.onerror = function(event) {
            reject(new Error(`Failed to open database: ${event}`));
        };
    });
}

const clearAllIndexedDB = async (): Promise<void> => {
    const databases = await window.indexedDB.databases();

    for (const db of databases) {
        if (db.name.indexOf('reloaded') === -1) {
            continue;
        }

        await clearDatabase(db.name);
    }
}

export const WithEmptyCachesOnNewSession = ({children}: { children: React.ReactNode }) => {
    const [cleared, setCleared] = React.useState(false);
    const [error, setError] = React.useState<Error | null>(null);

    React.useEffect(() => {
        const alreadyCleared = sessionStorage.getItem("__cachesCleared__");

        if (alreadyCleared) {
            console.log('TEMP: during development, caches already cleared.')
            setCleared(true);
        } else {
            console.log('TEMP: during development, clearing all caches.')

            sessionStorage.setItem("__cachesCleared__", "true");

            clearAllIndexedDB()
                .then(() => {
                    console.log('TEMP: during development, cleared all indexedDB caches.');
                    setCleared(true);
                })
                .catch(e => {
                    console.error('TEMP: during development, failed to clear all indexedDB caches.')
                    setError(e);
                });
        }
    }, []);

    if (!cleared) {
        return (
            <div>Rensar cachar - vänligen vänta....</div>
        )
    }

    if (error) {
        return (
            <div>Det gick inte att rensa cachar. Ladda om sidan.</div>
        )
    }

    return (
        <>{children}</>
    )
}