import { Avatar, StandardComponent } from '@reloaded/core-components';
import { AdministratorApi } from '@reloaded/integration';
import { useAuthentication } from '@reloaded/login-app';
import * as React from 'react';
import { useEffect } from 'react';

const AUTHORITY_RESOURCE_NAME_PATTERN =
  /r:administrator:(?<administratorId>\d+)/;

export interface MyProfilePictureProps {
  size?: 'small' | 'medium' | 'large';
  type?: 'impersonator' | 'self';
  onClick?: React.MouseEventHandler<HTMLElement>;
}
const avatarType = {
  self: Avatar.Type.AVATAR_INTERNAL,
  impersonator: Avatar.Type.AVATAR_EXTERNAL_1,
};
const avatarSizes = {
  small: Avatar.Size.LARGE,
  medium: Avatar.Size.XLARGE,
  large: Avatar.Size.XXLARGE,
};

export const MyProfilePicture = Avatar.extend(
  StandardComponent({ name: 'MyProfilePicture' })(
    ({ size = 'small', type = 'self', ...props }: MyProfilePictureProps) => {
      const { authorityResourceName } = useAuthentication();
      const [{ profilePictureId, loading, error }, setState] = React.useState<{
        profilePictureId?: number;
        loading: boolean;
        error?: Error;
      }>({ loading: true });

      useEffect(() => {
        if (!authorityResourceName) {
          return undefined;
        }

        const match = AUTHORITY_RESOURCE_NAME_PATTERN.exec(
          authorityResourceName,
        );

        if (!match) {
          return undefined;
        }

        const administratorId = parseInt(match.groups.administratorId, 10);

        AdministratorApi.getAdministrator(administratorId, 'profilePictures')
          .then((administrator) =>
            setState({
              profilePictureId:
                administrator.profilePictures.smallProfilePictureId ||
                administrator.profilePictures.largeContactPictureId,
              loading: false,
            }),
          )
          .catch((error) => setState({ loading: false, error }));
      }, [authorityResourceName]);

      if (loading) {
        return undefined;
      }

      if (error) {
        throw error;
      }

      return (
        <Avatar
          {...props}
          image={
            profilePictureId && `/file-api/v1/files/${profilePictureId}/data`
          }
          size={avatarSizes[size]}
          type={avatarType[type]}
          filter={Avatar.Filter.BLACK_AND_WHITE}
        />
      );
    },
  ),
);
