import { useContentBundle } from '@reloaded/content-management';
import { Tabs } from '@reloaded/core-components';
import * as React from 'react';
import { ReactNode, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function DashboardTabs() {
  const content = useContentBundle('showcase');
  const tabs: { label: ReactNode; path: string }[] = useMemo(
    () => [
      { label: content.get('showcase.start'), path: '/' },
      {
        label: content.get('showcase.chipinput'),
        path: '/dashboard/showcase/chip-input',
      },
      {
        label: content.get('showcase.overflowcontainer'),
        path: '/dashboard/showcase/overflow-container',
      },
      {
        label: content.get('showcase.actionable'),
        path: '/dashboard/showcase/actionable',
      },
      {
        label: content.get('showcase.dropdown'),
        path: '/dashboard/showcase/dropdown',
      },
      {
        label: content.get('showcase.contactcard'),
        path: '/dashboard/showcase/contact-card',
      },
      {
        label: content.get('showcase.expander'),
        path: '/dashboard/showcase/expander',
      },
      {
        label: content.get('showcase.buttons'),
        path: '/dashboard/showcase/buttons',
      },
      { label: content.get('showcase.tabs'), path: '/dashboard/showcase/tabs' },
      {
        label: content.get('showcase.layout'),
        path: '/dashboard/showcase/layout',
      },
      {
        label: content.get('showcase.inputs'),
        path: '/dashboard/showcase/inputs',
      },
      {
        label: content.get('showcase.datepicker'),
        path: '/dashboard/showcase/date-picker',
      },
      {
        label: content.get('showcase.steps'),
        path: '/dashboard/showcase/steps',
      },
      {
        label: content.get('showcase.weekpicker'),
        path: '/dashboard/showcase/week-picker',
      },
    ],
    [content?.loaded],
  );

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Tabs onValueChange={(value) => navigate(value)}>
      <Tabs.List>
        {tabs.map((tab, index) => (
          <Tabs.Trigger key={index} value={tab.path} title={tab.label} />
        ))}
      </Tabs.List>
    </Tabs>
  );
}
