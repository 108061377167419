import { Flex } from '@reloaded/core-components';
import { ErrorPanel } from '@reloaded/reloaded-ui';
import * as React from 'react';
import { ErrorCategory } from '../domain';

function ErrorDisplay({ error }: { error?: Error }) {
  return <ErrorPanel error={error} />;
}

type ErrorPageProps = {
  error: Error;
  category?: ErrorCategory;
};

export default function ErrorPage({ error, category }: ErrorPageProps) {
  // TODO: Is category necessary since the error should contain all the information? However, it wasn't used in the previous commit.

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ width: '100vw', height: '100vh' }}
    >
      <ErrorDisplay error={error} />
    </Flex>
  );
}
