import * as React from 'react';
import { ReactNode, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { fetch } from '@reloaded/integration';

export interface ContextDataCacheEntry {
  url: string;
  response: string;
}

class ContextDataManager {
  private _cache: ContextDataCacheEntry[] = [];

  add(url: string, response: string) {
    this._cache.push({ url, response });
  }

  clear() {
    this._cache = [];
  }

  all() {
    return this._cache;
  }
}

const ContextDataContext = React.createContext<ContextDataManager>(null);

export function ContextData({ children }: { children: ReactNode }) {
  const location = useLocation();
  const manager = useMemo(() => new ContextDataManager(), []);

  useEffect(() => {
    // clear caches
    manager.clear();
  }, [location]);

  useEffect(() => {
    const { close } = fetch.intercept((next, input, init) => {
      return next(input, init).then((response) => {
        if (
          response.status === 200 &&
          response.headers.get('Content-Type') === 'application/json'
        ) {
          response
            .clone()
            .json()
            .then((json) => manager.add(input.toString(), json));
        }

        return response;
      });
    });

    return () => {
      close();
    };
  }, []);

  return (
    <ContextDataContext.Provider value={manager}>
      {children}
    </ContextDataContext.Provider>
  );
}

export function useContextData() {
  const manager = useContext(ContextDataContext);

  return manager.all();
}
