import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  useEvent,
  Skin,
  AppEvent,
  useEventDispatcher,
  HTMLBindings,
} from '@reloaded/core-components';
import {
  Preference,
  PreferenceApi,
  PreferenceNamespace,
} from '@reloaded/integration';
import DefaultTheme from './DefaultTheme';

import { ErrorEvent } from '@reloaded/reloaded-ui';

import { LoadingScreen } from './LoadingScreen';

class SkinChangedEvent implements AppEvent<SkinChangedEvent['type']> {
  public static readonly TYPE = 'SkinChanged';

  public readonly type = SkinChangedEvent.TYPE;

  constructor(
    public readonly oldSkinName: string,
    public readonly newSkinName: string,
  ) {}
}
export function ThemedApp({ children }: React.PropsWithChildren<unknown>) {
  const [skinName, setSkinName] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [skin, setSkin] = useState<Skin>();
  const eventDispatcher = useEventDispatcher();

  useEvent('SkinChanged', (e: SkinChangedEvent) => {
    setSkinName(e.newSkinName);
  });

  const updateTheme = (newSkinName: string) => {
    setSkinName(newSkinName);
    setLoading(false);
  };

  useEffect(() => {
    PreferenceApi.getMyPreferences(PreferenceNamespace.THEME)
      .then((preferences: Preference[]) => {
        if (preferences.length === 0) {
          PreferenceApi.createPreference({
            namespace: PreferenceNamespace.THEME,
            properties: {
              'r-skin': 'reloaded',
            },
          })
            .then((preference: Preference) => {
              updateTheme(preference.properties['r-skin']);
            })
            .catch((e) => {
              console.error('Failed to create preferences for theme.', e);
              updateTheme('reloaded');
              eventDispatcher(
                new ErrorEvent(e, {
                  messageKey: 'error.preferences.theme.create',
                  defaultMessage: 'Failed to create preferences for theme.',
                  values: { namespace: 'theme' },
                }),
              ).then();
            });
        } else {
          updateTheme(preferences[0].properties['r-skin']);
        }
      })
      .catch((_) => {
        updateTheme('reloaded');
      });
  }, []);

  useEffect(() => {
    switch (skinName) {
      case 'none':
        import('@reloaded/reloaded-skin').then((skin: any) =>
          setSkin(new skin.default() as Skin),
        );
        break;
      case 'reloaded':
        import('@reloaded/reloaded-skin').then((skin: any) =>
          setSkin(new skin.default() as Skin),
        );
        break;
      case 'matrix':
        import('@reloaded/matrix-skin').then((skin: any) =>
          setSkin(new skin.default() as Skin),
        );
        break;
      case 'glass':
        import('@reloaded/glass-skin').then((skin: any) =>
          setSkin(new skin.default() as Skin),
        );
        break;
      case 'midnight':
        import('@reloaded/midnight-skin').then((skin: any) =>
          setSkin(new skin.default() as Skin),
        );
        break;
    }
  }, [skinName]);

  if (!skin || loading) {
    return <LoadingScreen />;
  }

  return (
    <DefaultTheme bindings={HTMLBindings} skin={skin}>
      {children}
    </DefaultTheme>
  );
}

export default ThemedApp;
