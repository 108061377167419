import * as React from 'react';
import {
  Frame,
  Icon,
  IconButton,
  IconButtonSize,
  Popover,
  Popper,
} from '@reloaded/core-components';
import { ChatFrame } from './ChatFrame';

export interface ChatbotIconProps {}

export const ChatbotIcon: React.FC<ChatbotIconProps> = () => {
  const [opened, setOpened] = React.useState(false);

  return (
    <Popover open={opened} onOpenChange={setOpened}>
      <Popover.Trigger>
        <IconButton size={IconButtonSize.LARGE} onClick={() => setOpened(true)}>
          <Icon name="arnold" />
        </IconButton>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content>
          <ChatFrame />
        </Popover.Content>
      </Popover.Portal>
    </Popover>
  );
};
