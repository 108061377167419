import { App, AppEntry } from '@reloaded/core-ui';
import { Route } from 'react-router-dom';
import * as React from 'react';
import { importRemote } from 'module-federation-import-remote';
import { getAppUri } from '../app/getAppUri';

const PRESENTATIONS_APP_KEY = 'presentationsApp';

export default function PresentationsRoutes() {
  return (
    <Route
      path="/presentations/*"
      element={
        <AppEntry<App>
          path="/presentations"
          loadingFallback={<div>Loading...</div>}
          load={() =>
            importRemote({
              url: getAppUri(PRESENTATIONS_APP_KEY, '/apps/presentations-app'),
              scope: PRESENTATIONS_APP_KEY,
              module: PRESENTATIONS_APP_KEY,
              remoteEntryFileName: 'remoteEntry.js',
            })
              .then((module: unknown) => {
                // eslint-disable-next-line no-console
                console.log(`Completed loading ${PRESENTATIONS_APP_KEY}`);

                const { default: app } = module as { default: App };

                return app;
              })
              .catch((e) => {
                // eslint-disable-next-line no-console
                console.error(
                  `Got an error when loading ${PRESENTATIONS_APP_KEY}: ${e}`,
                );

                return Promise.reject(e);
              })
          }
        />
      }
    />
  );
}
