/* eslint-disable import/prefer-default-export */
import { configureServiceWorker } from './serviceWorker';
import { configureFetch } from './fetch';
import { configureXhr } from './xhr';

export const configurations = [
  configureFetch,
  configureServiceWorker,
  configureXhr,
];
